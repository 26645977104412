import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import Layout from '../../../layout/Layout/Layout.js';
import Video from '../Video';
import Informacoes from '../Informacoes';
import Galeria from '../Galeria';
import CoHaut from '../CoHaut';
import AreaComum from '../AreaComum';
import Footer from '../Footer';

import './Empreendimento.css';

export default function COHAUT002() {
  gsap.registerPlugin(ScrollToPlugin);

  useEffect(() => {
    gsap.to(window, {
      scrollTo: 0
    });
  }, []);

  const [canWheel, setCanWheel] = useState(true);
  const [indicators, setIndicators] = useState(
    [
      {
        label: 'Home',
        to: '.empreendimento',
        active: true
      },
      {
        label: 'Informações',
        to: '.informacoes',
        active: false
      },
      {
        label: 'Galeria',
        to: '.galeria',
        active: false
      },
      {
        label: 'CO-HAUT',
        to: '.e-co-haut',
        active: false
      },
      {
        label: 'A Nossa Área Comum',
        to: '.empreendimento__area1',
        active: false,
      },
      {
        label: 'A Área Comum de Todos',
        to: '.empreendimento__area2',
        active: false,
      },
      {
        label: 'Contato',
        to: '.e-footer',
        active: false,
      }
    ]
  );

  useEffect(() => {
    setCanWheel(false);
    indicators.map(indicator => {
      if (indicator.to !== ".informacoes" && indicator.active) {
        setCanWheel(true);
      }
    });
  }, [indicators]);

  const updateIndicatorActive = to => {
    setIndicators(indicators.map(indicator => {
      if (indicator.to === to) {
        return {...indicator, active: true}
      } else {
        return {...indicator, active: false}
      }
    }));
  }

  const returnIndicators = () => {
    return indicators;
  }

  const handleCanWheel = payload => {
    setCanWheel(payload);
  }

  const imagesGaleria = [
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/1.jpg'), caption: 'CO-HAUT 002 | Fachada', alt: 'imagem', active: true, initial: true },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/2.jpg'), caption: 'CO-HAUT 002 | Vista', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/3.jpg'), caption: 'CO-HAUT 002 | Terraço e Piscina privativa', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/4.jpg'), caption: 'CO-HAUT 002 | Banheiro com vista', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/5.jpg'), caption: 'CO-HAUT 002 | Fachada', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/6.jpg'), caption: 'CO-HAUT 002 | Vista Superior', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/7.jpg'), caption: 'CO-HAUT 002 | Apartamento', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/8.jpg'), caption: 'CO-HAUT 002 | Apartamento', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/9.jpg'), caption: 'CO-HAUT 002 | Apartamento', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/10.jpg'), caption: 'CO-HAUT 002 | Fachada', alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL1/11.jpg'), caption: 'CO-HAUT 002 | Vista Superior', alt: 'imagem', active: false },
  ];

  const imagesArea1 = [
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/1.jpg'), alt: 'imagem', active: true, initial: true },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/2.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/3.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/4.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/5.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/6.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/7.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/8.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/9.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/10.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL2/11.jpg'), alt: 'imagem', active: false },
  ];

  const imagesArea2 = [
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/1.jpg'), alt: 'imagem', active: true, initial: true },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/2.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/3.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/4.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/5.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/6.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/7.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/8.jpg'), alt: 'imagem', active: false },
    { src: require('../../../assets/imgs/CH002/CARROSSEL3/9.jpg'), alt: 'imagem', active: false },
  ];

  return(
    <Layout
      sections={indicators}
      topbar={{showLogo: false, showSearch: false}}
      updateIndicatorActive={updateIndicatorActive}
      updateSection={returnIndicators}
      canWheel={canWheel}
    >
      <div className="empreendimento co-haut002">
        <Video title="CO-HAUT" number="002" src={require('../../../assets/videos/CH002.mp4')} />
        <Informacoes
          handleCanWheel={handleCanWheel}
          address={{
            city: 'Recife',
            district: 'Boa Viagem',
            street: <>Rua Ana Camelo<br/>da Silva</>,
            number: 251
          }}
          types={ <>29m<sup>2</sup> a 108m<sup>2</sup></> }
          units={81}
          title="CO-HAUT 002"
          subtitle="É sobre morar no espaço essencial, com o máximo de experiências."
          text={
            <>
              Um marco na paisagem do bairro de Boa Viagem, cada detalhe do CO-HAUT 002 foi pensado para se conectar com o seu entorno. Desde o térreo do edifício, todo aberto para a cidade, valorizando o que a Rua Ana Camelo da Silva tem de melhor - a calmaria, a arborização, um ambiente verdadeiramente agradável - e convidando a vizinhança a viver novas experiências com um bistrô e uma pocket livraria em uma praça que se estende até a rua, assumindo a função de equipamento urbano e requalificando a localização onde está inserido através da gentileza urbana. <br />
              Sua arquitetura se deleita sobre a paisagem de um dos ecossistemas mais bonitos do Recife, o imenso mar verde que é o parque dos manguezais, vista dos nossos apartamentos e áreas comuns espalhadas ao longo do edifício. E, por que não, apartamentos de 40m² com terraço e piscina privativa com esse mesmo plano de fundo? Nos banheiros, janelas que vão do piso ao teto fazem do banho um momento singular. Valorizando a convivência e interação, os espaços comuns estão distribuídos por todos os pavimentos do CO-HAUT 002, proporcionando experiências únicas e fazendo do edifício um organismo pulsante, cheio de vida.
            </>
          }
          page="CO-HAUT002"
        />
        <Galeria
          images={imagesGaleria}
          page="CO-HAUT002"
        />
        <CoHaut
          title="O nosso e o"
          subtitle="de todos"
          text={<>
            Promover a conexão é um dos propósitos da CO-HAUT. Muito além de encontros e interações, é sobre viver novas experiências e compartilhar antigas, sobre se conectar com pessoas que estão na mesma sintonia que você. <br />
            Cada CO-HAUT é um organismo totalmente único, com ambientes pensados para o NOSSO uso exclusivo, mas também espaços selecionados abertos para uso de TODOS, assim fortalecendo vínculos com a cidade que nos acolhe.
          </>}
          page="CO-HAUT002"
        />
        <div className="empreendimento__area1">
          <AreaComum
            inLeft
            images={imagesArea1}
            title={<>A nossa área <span>comum</span> .</>}
            subtitle="Morar no espaço essencial e, ainda assim, poder viver o máximo de experiências. No CO-HAUT 002, sete espaços gourmet de alto padrão possibilitam viver um momento diferente para cada dia da semana. SPA e fitness para uma rotina cheia de autocuidados. Lavanderia com sala de jogos, três ambientes de estar com TV e pet space para ajudar a desopilar e uma piscina com borda infinita para curtir a vista mais deslumbrante da cidade."
            page="CO-HAUT002"
          />
        </div>
        <div className="empreendimento__area2">
          <AreaComum
            inRight
            images={imagesArea2}
            title={<>A área comum <span>de todos</span> .</>}
            subtitle="Queremos que ainda mais gente experimente esse estilo contemporâneo de viver. Por isso, todo o pavimento térreo será aberto para a cidade, um ambiente convidativo que conta com bistrô e mini livraria com café. Logo acima, coworking, barbearia e esmalteria abertos para a cidade, proporcionando ainda mais convivência e conexão com o nosso entorno."
            page="CO-HAUT002"
          />
        </div>
        <Footer
          sections={indicators}
          changeSection={updateIndicatorActive}
          page="CO-HAUT002"
          number="002"
          coords={[-34.892095, -8.107973]}
        />
      </div>
    </Layout>
  );
}
