import React from 'react';
import './ContextMenu.css';

export default function ContextMenu({x, y, menu, isOpen, handleOpenMenu}) {
  const fecharMenu = () => {
    handleOpenMenu(false);
  }

  return(
    <>
      {
        isOpen &&
        <div
          className="context-menu"
          onClick={fecharMenu}
          onContextMenu={fecharMenu}
        >
          <div
            style={{top: `${y}px`, left: `${x}px`}}
            className="context-menu-dropdown"
            onClick={ e => { e.stopPropagation() } }
            onContextMenu={ e => { e.stopPropagation() } }
          >
            { menu }
          </div>
        </div>
      }
    </>
  );
}
