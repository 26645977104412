import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import FullMenu from '../../components/FullMenu';
import logoCoHaut from '../../assets/logo_branco.png'

import './Sidebar.css'
//import menuOutline from '../../assets/icones/menu-outline.svg';
import { ReactComponent as MenuIcon } from '../../assets/icones/menu-outline.svg';
import { ReactComponent as ArrowDown } from '../../assets/icones/down.svg';
import { ReactComponent as Mouse } from '../../assets/icones/mouse.svg';
import { ReactComponent as ArrowUp } from '../../assets/icones/up.svg';

export default function Sidebar({sections, updateIndicatorActive, changeWheeling, wheeling}) {
    gsap.registerPlugin(ScrollToPlugin);

    const [showArrowUp, setShowArrowUp] = useState(false);
    const [showArrowDown, setShowArrowDown] = useState(true);
    const [indexActive, setActiveTo] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        for (const [index, item] of sections.entries()) {
            if (item.active) {
                setActiveTo(index);
                if (index > 0) {
                    setShowArrowUp(true);
                    if (index === (sections.length - 1)) {
                        setShowArrowDown(false);
                    } else {
                        setShowArrowDown(true);
                    }
                } else {
                    setShowArrowDown(true);
                    setShowArrowUp(false);
                }
            }
        }
    }, [sections]);

    const mouseReset = () => {
        gsap.to('.mouse-watcher', {
            cursor: 'auto',
            width: '100px',
            height: '100px',
            top: '-50px',
            left: '-50px',
            border: 'none',
            borderRadius: '50%',
            backgroundColor: '#fff',
            opacity: .6
        });
    }

    const scrollUp = () => {
        if (indexActive === 0) {
            setShowArrowUp(false);
            return;
        }

        if (wheeling) {
            return;
        }

        changeWheeling(true);
        const indexTo = indexActive - 1;
        if (indexTo in sections) {
            gsap.to(window, {
                scrollTo: sections[indexTo].to,
                duration: 1.7,
                ease: "expo.out",
                onComplete: () => {
                    setActiveTo(indexTo);
                    let tempIndexTo = indexActive - 1;
                    if (tempIndexTo <= 0) {
                        setShowArrowUp(false);
                    }
                    setShowArrowDown(true);
                    changeWheeling(false);
                },
                onStart: () => {
                    mouseReset();
                    updateIndicatorActive(sections[indexTo].to);
                }
            });
        }
    }

    const scrollDown = () => {
        if (wheeling) {
            return;
        }

        changeWheeling(true);
        const indexTo = indexActive + 1;
        if (indexTo in sections) {
            gsap.to(window, {
                scrollTo: sections[indexTo].to,
                duration: 1.7,
                ease: "expo.out",
                onComplete: () => {
                    setActiveTo(indexTo);
                    let tempIndexTo = indexTo + 1;
                    if (!(tempIndexTo in sections)) {
                        setShowArrowDown(false);
                    }
                    setShowArrowUp(true);
                    changeWheeling(false);
                },
                onStart: () => {
                    mouseReset();
                    updateIndicatorActive(sections[indexTo].to);
                }
            });
        }
    }

    const changeMenu = value => {
        setMenuOpen(value);
    }

    return(
        <div className="sidebar">
            <FullMenu open={menuOpen} closeMenu={changeMenu} />
            <div className="sidebar__wrapper">                
                <div className="sidebar__top">
                    <div className="icon__wrapper">
                        <MenuIcon className="sidebar__icon" id="menu-icon" onClick={ () => { setMenuOpen(true); } } />           
                    </div>     
                </div>
                <div className="sidebar__bottom">
                <img src={logoCoHaut} alt="Logo-CO-Haut" className="topbar__logo" id="cohautLogo" />
                    <div className="icon__group">
                        { showArrowUp &&
                            <div className="icon__wrapper">
                                <button className="sidebar__scroll-button" id="sidebar-up-click" onClick={scrollUp} >
                                    <ArrowUp className="sidebar__icon sidebar__icon--up arrow" />
                                </button>
                            </div>
                        }
                        { (showArrowUp || showArrowDown) &&
                            <div className="icon__wrapper">
                                <Mouse className="sidebar__icon" id="mouse-icon" />
                            </div>
                        }
                        { showArrowDown &&
                            <button className="sidebar__scroll-button" id="sidebar-down-click" onClick={scrollDown}>
                                <div className="icon__wrapper">
                                    <ArrowDown className="sidebar__icon sidebar__icon--down arrow" />
                                </div> 
                            </button> 
                        }                
                    </div>
                </div>
            </div>
        </div>        
    );

}