import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import SearchResult from '../../components/SearchResult';

import './Topbar.css';
import logoWhite from '../../assets/logos/logo-white.png';
import { ReactComponent as SearchIcon } from '../../assets/icones/search.svg';

export default function Topbar({showLogo, showSearch, updateSection}) {
    gsap.registerPlugin(ScrollToPlugin);

    const [query, setQuery] = useState(null);
    const [results, setResults] = useState([]);
    const [hasMath, setHasMath] = useState(false);

    const removerAcentos = string => {
        var mapaAcentosHex  = {
            a : /[\xE0-\xE6]/g,
            e : /[\xE8-\xEB]/g,
            i : /[\xEC-\xEF]/g,
            o : /[\xF2-\xF6]/g,
            u : /[\xF9-\xFC]/g,
            c : /\xE7/g,
            n : /\xF1/g,
            '-' : /\s/g
        };

        for ( var letra in mapaAcentosHex ) {
            var expressaoRegular = mapaAcentosHex[letra];
            string = string.replace( expressaoRegular, letra );
        }

        return string;
    }

    useEffect(() => {
        if (!hasMath) {
            setResults([]);
        }
    }, [hasMath]);

    useEffect(() => {
        if (query === '') {
            setQuery(null);
            setResults([]);
        }

        if (query !== null) {
            const sQuery = {
                originalText: query,
                prefix: 's__',
                selector: '.',
                searchText: removerAcentos(query.toLowerCase()),
                toString() {
                    return this.selector + this.prefix + this.searchText
                }
            }

            // primeiro pesquiso os parentes
            // porque evito if para verificar se é parente
            // e acredito que ganho desempenho
            const parentElements = document.querySelectorAll(`.s__parent${sQuery.toString()}`);

            for (let element of parentElements) {
                setResults([...results, {
                    page: element.dataset.page,
                    session: element.dataset.session,
                    to: element.dataset.to ? element.dataset.to : null
                }]);
            }

            // Textos curtos agora
            const tinyTexts = document.querySelectorAll('.s__tiny-text, .s__long-text');
            setHasMath(false);
            for (let element of tinyTexts) {
                if (element.innerHTML.toLowerCase().search(sQuery.originalText.toLowerCase()) !== -1) {
                    let key = results.find(result => result.key === element.dataset.key);
                    if (key === undefined) {
                        setResults([...results, {
                            page: element.dataset.page,
                            session: element.dataset.session,
                            to: element.dataset.to ? element.dataset.to : null,
                            key: element.dataset.key
                        }]);
                    }
                    setHasMath(true);
                }
            }

            if (parentElements.length === 0 && !hasMath) {
                setResults([]);
            }
        }
    }, [query]);

    const resultMath = mathed => {
        updateSection(mathed.to);
        setQuery('');
    }

    const inputFocus = () => {
        document.querySelector('.topbar__input').focus();
    }

    const removeFocus = () => {
        const inputGroup = document.querySelector('.input--group');
        const topbarInput = document.querySelector('.topbar__input');

        if (inputGroup !== null && topbarInput !== null) {
            inputGroup.style.background = 'none';
            topbarInput.style.display = 'none';
        }
    }

    const expandSearchBar = e => {
        const w = document.body.clientWidth;
        if (w <= 425) {
            const inputGroup = document.querySelector('.input--group');
            const topbarInput = document.querySelector('.topbar__input');

            if (inputGroup !== null && topbarInput !== null) {
                inputGroup.style.background = 'rgba(137,139,145, .5)';
                topbarInput.style.display = 'block';
            }
        }
    }

    return(
        <div className="topbar">
            <div className="layout__container">
                <div className="topbar__wrapper">
                    {
                        showSearch &&
                        <div className="topbar__left">
                            <div
                                className="input--group"
                                onClick={() => { inputFocus() }}
                            >
                                <div className="input--prepend">
                                    <div className="input__icon--wrapper">
                                        <SearchIcon className="input__icon" onClick={expandSearchBar} />
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className="topbar__input"
                                    placeholder="Search"
                                    onChange={e => setQuery(e.target.value)}
                                    onBlur={removeFocus}
                                />
                            </div>
                            <SearchResult
                                results={results}
                                onMath={resultMath}
                            />
                        </div>
                    }
                    { showLogo
                         &&
                        <div className="topbar__right">
                            <div className="topbar__logo--wrapper">
                                <img src={logoWhite} alt="CO-Haut" className="topbar__logo" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
