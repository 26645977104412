import React, { useState } from 'react';
import { gsap } from 'gsap';

import './Video.css';

import logo from '../../../assets/logos/co-haut-vertical.png';

export default function Video({src, title, number}) {
	const [options, setOptions] = useState({});
	
	const videoPlay = () => {
		const body = document.querySelector("body");

		if (body.clientWidth > 767) {
			setOptions({
				width: 300,
				onStart() {
					document.querySelector('.overlay__wrapper').style.display = 'block';
				}
			});
		} else {
			setOptions({
				height: "30%",
				onStart() {
					document.querySelector('.overlay__wrapper').style.display = 'block';
				}
			});
		}

		gsap.to('.video__overlay', options);
	}

	const videoPause = () => {
		const body = document.querySelector("body");

		if (body.clientWidth > 767) {
			setOptions({
				width: 0,
				onStart() {
					document.querySelector('.overlay__wrapper').style.display = 'none';
				}
			});
		} else {
			setOptions({
				height: 0,
				onStart() {
					document.querySelector('.overlay__wrapper').style.display = 'none';
				}
			})
		}

		gsap.to('.video__overlay', options);
	}
	
	return(
		<div
			className={`video s__parent s__home s__empreendimento s__co-haut${number}`}
			data-page={`CO-HAUT${number}`}
			data-session="Video"
		>
			<label
                className="d-none s__tiny-text"
                data-page={`CO-HAUT${number}`}
                data-session="Vídeo"
                data-key="video"
            >
                empreendimentos CO-HAUT{number}
            </label>
			<div className="video__marca">
				<img src={logo} alt="co-haut" />
			</div>
			<div className="layout__container">
				<div className="video__src">
					<div className="video__content">
						{/* Mobile Only */}
						{/* <div className="video__overlay--mobile">
							
						</div> */}

						<div className="video__overlay">
							<div className="overlay__wrapper">
								<div className="overlay__badge">{ title }</div>
								<div className="overlay__number">{ number }</div>
							</div>
						</div>
						<video
							className="video__tag"
							controls
							onPlay={videoPlay}
							onPause={videoPause}
							autoPlay
							muted
						>
							<source src={src} type="video/mp4"/>
						</video>
					</div>
				</div>
			</div>
		</div>
	)
}