import React from 'react';
import './Pictures.css';
import test from '../../assets/imgs/pictures-teste.svg'


export default function Pictures(){
    return (
        <div className="div-pictures">
            <div className="box-img-texto">
                <img src={test} alt="teste"></img>
                <div className="box-desc-img">
                    <h2>FRASE PARA AQUI.</h2>
                    <p>Lorem ipsum dolor sit amet, mea ei modus nemore cetero, sed id propriae offendit suscipit. Partem ceteros quaestio vis ex, debitis electram consetetur ne cum. Lorem ipsum dolor sit amet, mea ei modus nemore cetero, sed id propriae offendit suscipit. Partem ceteros quaestio vis ex, debitis electram consetetur nemea ei modus nemore cetero,  cum.</p>
                </div>
            </div>
        </div>
    )
}