import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import Layout from '../../../layout/Layout/Layout.js';
import Video from '../Video';
import Informacoes from '../Informacoes';
import Galeria from '../Galeria';
import CoHaut from '../CoHaut';
import AreaComum from '../AreaComum';
import Footer from '../Footer';

import './Empreendimento.css';

export default function COHAUT001() {
	gsap.registerPlugin(ScrollToPlugin);

	useEffect(() => {
		gsap.to(window, {
			scrollTo: 0
		});
	}, []);

	const [canWheel, setCanWheel] = useState(true);
	const [indicators, setIndicators] = useState(
        [
            {
            	label: 'Home',
            	to: '.empreendimento',
            	active: true
            },
            {
            	label: 'Informações',
            	to: '.informacoes',
            	active: false
            },
            {
            	label: 'Galeria',
            	to: '.galeria',
            	active: false
            },
            {
            	label: 'CO-HAUT',
            	to: '.e-co-haut',
            	active: false
            },
            {
            	label: 'A Nossa Área Comum',
            	to: '.empreendimento__area1',
            	active: false,
            },
            {
            	label: 'A Área Comum de Todos',
            	to: '.empreendimento__area2',
            	active: false,
            },
            {
            	label: 'Contato',
            	to: '.e-footer',
            	active: false,
            }
        ]
    );

	  useEffect(() => {
	    setCanWheel(false);
	    indicators.map(indicator => {
	      if (indicator.to !== ".informacoes" && indicator.active) {
          setCanWheel(true);
        }
      });
    }, [indicators]);

    const updateIndicatorActive = to => {
        setIndicators(indicators.map(indicator => {
            if (indicator.to === to) {
                return {...indicator, active: true}
            } else {
                return {...indicator, active: false}
            }
        }));
    }

    const returnIndicators = () => {
        return indicators;
    }

		const handleCanWheel = payload => {
			setCanWheel(payload);
		}

    const imagesGaleria = [
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/1.jpg'), caption: 'CO-HAUT 001 | Fachada', alt: 'imagem', active: true, initial: true },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/2.jpg'), caption: 'CO-HAUT 001 | Coworking (Casarão)', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/3.jpg'), caption: 'CO-HAUT 001 | Piscina Comum', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/4.jpg'), caption: 'CO-HAUT 001 | Vista', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/5.jpg'), caption: 'CO-HAUT 001 | Rua Compartilhada', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/6.jpg'), caption: 'CO-HAUT 001 | Vista', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/7.jpg'), caption: 'CO-HAUT 001 | Vista', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/8.jpg'), caption: 'CO-HAUT 001 | Vista', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/9.jpg'), caption: 'CO-HAUT 001 | Apartamento', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/10.jpg'), caption: 'CO-HAUT 001 | Apartamento', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/11.jpg'), caption: 'CO-HAUT 001 | Apartamento', alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL1/12.jpg'), caption: 'CO-HAUT 001 | Apartamento', alt: 'imagem', active: false },
    ];

    const imagesArea1 = [
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/1.jpg'), alt: 'imagem', active: true, initial: true },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/2.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/3.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/4.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/5.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/6.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/7.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/8.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/9.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/10.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/11.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/12.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/13.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/14.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL2/15.jpg'), alt: 'imagem', active: false },
    ];

    const imagesArea2 = [
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/1.jpg'), alt: 'imagem', active: true, initial: true },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/2.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/3.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/4.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/5.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/6.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/7.jpg'), alt: 'imagem', active: false },
        { src: require('../../../assets/imgs/CH001/CARROSSEL3/8.jpg'), alt: 'imagem', active: false },
    ];

	return(
		<Layout
			sections={indicators}
      topbar={{showLogo: false, showSearch: false}}
      updateIndicatorActive={updateIndicatorActive}
      updateSection={returnIndicators}
			canWheel={canWheel}
		>
			<div className="empreendimento co-haut001">
				<Video title="CO-HAUT" number="001" src={require('../../../assets/videos/CH001.mov')} />
				<Informacoes
						handleCanWheel={handleCanWheel}
            address={{
                city: 'Recife',
                district: 'Poço da Panela',
                street: 'Rua Chacon',
                number: 297
            }}
            types={ <>28m<sup>2</sup> a 48m<sup>2</sup></> }
            units={121}
            title="CO-HAUT 001"
            subtitle="O antigo e o novo em completa sintonia."
            text={
                <>
                    Casarões do século XIX cheios de charme e história fazem da Rua do Chacon, no Poço da Panela, o cenário perfeito para receber o CO-HAUT 001. Para nós, preservar o antigo é tão importante quanto planejar o novo. Por isso, o imóvel antigo existente no local será totalmente revitalizado, dando espaço a um coworking e café que serão totalmente integrados ao edifício e abertos para a cidade, conectando-se a uma alameda que será totalmente requalificada e compartilhada. Um local cheio de história e memórias afetivas que abrigará não só um espaço de trabalho, mas um ambiente para se conectar e compartilhar ideias. <br /><br />
                    No CO-HAUT 001, cada ambiente tem sua própria ambiência e identidade, possibilitando viver experiências totalmente diferentes. Teremos pub com barbearia e esmalteria, academia e mini box crossfit, uma lavanderia integrada com jogos e, por que não, poder receber seus convidados em um dos seis espaços gourmet de alto padrão localizados ao longo do edifício? Para arrematar, um rooftop com a vista do pôr do sol no Rio Capibaribe como plano de fundo da nossa piscina com borda infinita, uma vista única do bairro.
                </>
            }
            page="CO-HAUT001"
        />
				<Galeria
                    images={imagesGaleria}
                    page="CO-HAUT001"
                />
				<CoHaut
                    title="O nosso e o"
                    subtitle="de todos"
                    text={<>
                            Promover a conexão é um dos propósitos da CO-HAUT. Muito além de encontros e interações, é sobre viver novas experiências e compartilhar antigas, sobre se conectar com pessoas que estão na mesma sintonia que você. <br />
                            Cada CO-HAUT é um organismo totalmente único, com ambientes pensados para o NOSSO uso exclusivo, mas também espaços selecionados abertos para uso de TODOS, assim fortalecendo vínculos com a cidade que nos acolhe.
                        </>}
                    page="CO-HAUT001"
                />
				<div className="empreendimento__area1">
					<AreaComum
						inLeft
						images={imagesArea1}
						title={<>A nossa área <span>comum</span> .</>}
						subtitle="A comodidade e praticidade de ter a disposição vários ambientes de alto padrão proporcionando experiências totalmente distintas. O CO-HAUT 001 integra seis espaços gourmet para momentos únicos com pessoas especiais, SPA, academia, mini box crossfit, lavanderia com sala de jogos, pet space, living rooms e um rooftop com piscina comum com borda infinita e vista panorâmica."
                        page="CO-HAUT001"
					/>
				</div>
				<div className="empreendimento__area2">
					<AreaComum
						inRight
						images={imagesArea2}
						title={<>A área comum <span>de todos</span> .</>}
						subtitle="Queremos que ainda mais gente experimente esse estilo contemporâneo de viver. Por isso, coworking, café, pub, barbearia e esmalteria são abertos para a cidade, inovando a convivência e conectando a vida em suas múltiplas dimensões."
                        page="CO-HAUT001"
					/>
				</div>
				<Footer
                    sections={indicators}
                    changeSection={updateIndicatorActive}
                    page="CO-HAUT001"
                    number="001"
                    coords={[-34.919686, -8.0401]}
                />
			</div>
		</Layout>
	);
}
