import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import Navegator from '../../components/Navegator';

import './PageIndicator.css';

export default function PageIndicator({ pages, updateIndicatorActive }) {
    const [mouseIn, setMouseIn] = useState(false);
    const [reactivate, setReactivate] = useState(null);
    const [hasTimeout, setHasTimeout] = useState(false);

    useEffect(() => {
        if (mouseIn) {
            localStorage.setItem('mouseIn', 1);
        } else {
            localStorage.setItem('mouseIn', 0);
        }
    }, [mouseIn]);

    useEffect(() => {
        if (pages[0].active) {
            mouseEnter(`page-indicator-item${0}`);
            gsap.to('.pageIndicator__item:not(.pageIndicator__item--active) .pageIndicator__label', {
                opacity: 0
            });
            gsap.to('.pageIndicator__item:not(.pageIndicator__item--active) .pageIndicator__indicator', {
                background: 'transparent'
            });
            setMouseIn(false);
        }
    }, [pages]);

    const indicatorReset = () => {
        let _mouseIn = parseInt(localStorage.getItem('mouseIn'));
        if (!_mouseIn) {
            gsap.to('.pageIndicator__label--active', {
                opacity: 1,
                onStart() {
                    gsap.to('.pageIndicator__indicator--active', {
                        background: '#fff'
                    });
                }
            });
        }
    }

    const mouseEnter = (id, index) => {
        clearTimeout(reactivate);
        setHasTimeout(false);

        gsap.to('.pageIndicator__label--active', {
            opacity: 0,
            onStart() {
                gsap.to('.pageIndicator__indicator--active', {
                    background: 'transparent'
                });
            }
        });

        gsap.to(`#${id} .pageIndicator__label`, {
            opacity: 1,
            onStart() {
                gsap.to(`#${id} .pageIndicator__indicator`, {
                    background: '#fff'
                });
            }
        });
    }

    const mouseLeave = (id) => {
        if (hasTimeout) {
            clearTimeout(reactivate);
            setHasTimeout(false);
        }

        setHasTimeout(true);
        setReactivate(setTimeout(function() {
            indicatorReset();
        }, 600));

        gsap.to(`#${id} .pageIndicator__label`, {
            opacity: 0,
            onStart() {
                gsap.to(`#${id} .pageIndicator__indicator`, {
                    background: 'transparent'
                });
            },
        });
    }

    const idGenerator = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    const navegate = to => {
        Navegator.to(to);
        updateIndicatorActive(to);
    }

    const listItems = pages.map((item, index) => {
        const id = idGenerator();
        const idLi = `page-indicator-item${index}`;

        return(
            <li
                key={index}
                id={idLi}
                className={`
                    pageIndicator__item
                    ${ item.active ? 'pageIndicator__item--active' : '' }
                `}
                onMouseEnter={ () => { mouseEnter(idLi, index) } }
                {...(!(item.active) && { onMouseLeave: () => { mouseLeave(idLi, index) } })}
                {...(!(item.active) && { onClick: () => { navegate(item.to) } })}
            >
                <label
                    className={`pageIndicator__label ${ item.active ? 'pageIndicator__label--active' : '' }`}
                    onClick={ () => { document.getElementById(id).click() } }
                >
                    { item.label }
                </label>
                <div
                    className={`pageIndicator__indicator ${ item.active ? 'pageIndicator__indicator--active' : '' }`}
                    id={id}
                />
            </li>
        );
    });

    return(
        <div
            className="pageIndicator"
            onMouseEnter={() => { setMouseIn(true) }}
            onMouseLeave={() => { setMouseIn(false) }}
        >
            <ul className="pageIndicator__items">
                { listItems }
            </ul>
        </div>
    );

}

