import React from 'react';
import './CoHaut.css';

import image from '../../../assets/imgs/empreendimento-section.png';

export default function CoHaut({title, subtitle, text, page}) {
	return(
		<div className="layout__container">
			<div
				className="e-co-haut s__co-haut"
				data-page={page}
				data-section="CO-HAUT"
			>
				<div className="e-co-haut__linha">
					<div className="e-co-haut__coluna">
						<h2 className="e-co-haut__titulo">
							{title}<br />
							<span>{subtitle}</span> .
						</h2>
						<p className="e-co-haut__texto">
							{ text }
						</p>
					</div>
					<div className="e-co-haut__coluna e-co-haut__coluna--flex">
						<img className="e-co-haut__img" src={image} alt="Mulher fazendo acrobacia em cima de um quadrado com a logo do CO-HUAT" />
					</div>
				</div>
			</div>
		</div>
	);
}