import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default {
    to(selector) {
        gsap.to(window, {
            scrollTo: selector,
            duration: 1.7,
            ease: "expo.out",
            onComplete: () => {
                gsap.to('.mouse-watcher', {
                    scrollTrigger: {
                        trigger: '.empreendimentos',
                        toggleActions: 'restart'
                    },
                    width: 100,
                    height: 100,
                    top: -50,
                    left: -50,
                    borderRadius: '50%'
                });
            }
        });
    }
}