import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ContextMenu from '../../../components/ContextMenu';
import CustomMenu from '../../../components/CustomMenu';

import '../../../components/Fonts/Poppins/Poppins.js';
import './Footer.css';

import logo from '../../../assets/logos/logo-cohaut-white.svg';
import hautMini from '../../../assets/logos/haut-mini.png';
import { ReactComponent as FacebookIcon } from '../../../assets/icones/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icones/instagram.svg';

export default function Footer() {
    const history = useHistory();

    // Menu Contexto Personalizado
    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    const openMenu = (e, link) => {
      setMenuItems([
        {
          id: 0,
          label: 'Abrir em uma nova guia',
          action: () => {
            const win = window.open(link, '_blank');
            if (win !== null) {
              win.focus();
            }
            setOpenContextMenu(false);
          }
        }
      ]);

      setPosX(e.nativeEvent.x);
      setPosY(e.nativeEvent.y);

      setOpenContextMenu(true);
    }

    const handleOpenMenu = payload => {
      setOpenContextMenu(payload);
    }

    return(
        <div
            className="footer s__parent s__informacoes-de-contato"
            data-page="Home"
            data-session="Informações de Contato"
            data-to="footer"
        >
            <ContextMenu
              x={posX}
              y={posY}
              menu={<CustomMenu items={menuItems} />}
              isOpen={openContextMenu}
              handleOpenMenu={handleOpenMenu}
            />
            <div className="layout__container layout__container--relative">
                <div className="footer__content">
                    <img className="footer__logo" src={logo} alt="CO-HAUT" />
                    <div className="footer__contato">

                        { /* Desktop Only */ }
                        <div className="contato__item contato__item--desktop">
                            <h2
                                className="contato__titulo s__tiny-text"
                                data-page="Home"
                                data-session="Informações de Contato"
                                data-to="footer"
                                data-key="footer"
                            >
                                Conheça a HAUT
                            </h2>
                            <a
                              className="conheca__link"
                              href="https://haut.id"
                              target="__blank"
                              onContextMenu={e => { openMenu(e, 'https://haut.id') }}
                            >
                              haut.id
                            </a>
                        </div>
                        <div className="contato__item contato__item--desktop">
                            <h2
                                className="contato__titulo s__tiny-text"
                                data-page="Home"
                                data-session="Informações de Contato"
                                data-to="footer"
                                data-key="footer"
                            >
                                Endereço
                            </h2>
                            <p className="contato__endereco">JCPM Trade Center Av. Antônio de Góes, 60 Salas 903 e 904, Pina CEP 51010-000</p>
                        </div>
                        <div className="contato__item contato__item--large contato__item--desktop">
                            <h2
                                className="contato__titulo s__tiny-text"
                                data-page="Home"
                                data-session="Informações de Contato"
                                data-to="footer"
                                data-key="footer"
                            >
                                Entre em contato
                            </h2>
                            <button
                              className="contato__email"
                              onClick={() => { history.push('/contato') }}
                              onContextMenu={e => { openMenu(e, '/contato') }}
                            >
                                aqui
                            </button>
                            <div className="footer__redes-sociais contato__telefone">
                                <p>Redes Sociais</p>
                                <div>
                                    <a
                                      href="https://pt-br.facebook.com/HautIncorporadoraDesign/"
                                      target="__blank"
                                      onContextMenu={e => { openMenu(e, 'https://pt-br.facebook.com/HautIncorporadoraDesign/') }}
                                    >
                                        <FacebookIcon className="rede-social rede-social--facebook" />
                                    </a>
                                    <label
                                        className="d-none s__tiny-text"
                                        data-page="Home"
                                        data-session="Informações de Contato"
                                        data-to="footer"
                                        data-key="footer"
                                    >
                                        Facebook
                                    </label>
                                    <a
                                      href="https://www.instagram.com/haut_id/"
                                      target="__blank"
                                      onContextMenu={e => { openMenu(e, 'https://www.instagram.com/haut_id/') }}
                                    >
                                        <InstagramIcon className="rede-social rede-social--instagram" />
                                    </a>
                                    <label
                                        className="d-none s__tiny-text"
                                        data-page="Home"
                                        data-session="Informações de Contato"
                                        data-to="footer"
                                        data-key="footer"
                                    >
                                        Instagram
                                    </label>
                                </div>
                            </div>
                        </div>


                        { /* Mobile Only */ }
                        <div className="contato__has-subitem contato__has-subitem--mobile">
                            <div className="contato__item contato__item--mobile">
                                <h2 className="contato__titulo">Conheça a HAUT</h2>
                                <a
                                  className="conheca__link" href="https://haut.id"
                                  target="__blank"
                                  onContextMenu={e => { openMenu(e, 'https://haut.id') }}
                                >
                                  haut.id
                                </a>
                                <h2 className="contato__titulo">Endereço</h2>
                                <p className="contato__endereco">JCPM Trade Center Av. Antônio de Góes, 60 Salas 903 e 904, Pina CEP 51010-000</p>
                            </div>
                            <div className="contato__item contato__item--mobile">
                                <h2
                                    className="contato__titulo s__tiny-text"
                                    data-page="Home"
                                    data-session="Informações de Contato"
                                    data-to="footer"
                                    data-key="footer"
                                >
                                    Entre em contato
                                </h2>
                                <button className="contato__email">
                                    aqui
                                </button>
                                <div className="footer__redes-sociais contato__telefone">
                                    <p>Redes Sociais</p>
                                    <div>
                                        <a
                                          href="https://pt-br.facebook.com/HautIncorporadoraDesign/"
                                          target="__blank"
                                          onContextMenu={e => { openMenu(e, 'https://pt-br.facebook.com/HautIncorporadoraDesign/') }}
                                        >
                                            <FacebookIcon className="rede-social rede-social--facebook" />
                                        </a>
                                        <label
                                            className="d-none s__tiny-text"
                                            data-page="Home"
                                            data-session="Informações de Contato"
                                            data-to="footer"
                                            data-key="footer"
                                        >
                                            Facebook
                                        </label>
                                        <a
                                          href="https://www.instagram.com/haut_id/"
                                          target="__blank"
                                          onContextMenu={e => { openMenu(e, 'https://www.instagram.com/haut_id/') }}
                                        >
                                            <InstagramIcon className="rede-social rede-social--instagram" />
                                        </a>
                                        <label
                                            className="d-none s__tiny-text"
                                            data-page="Home"
                                            data-session="Informações de Contato"
                                            data-to="footer"
                                            data-key="footer"
                                        >
                                            Instagram
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="footer__copy--wrapper">
                        <span className="footer__copy">
                            designed by <img src={hautMini} alt="Haut" /> 2020
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}
