import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';

import './Header.css';
import { ReactComponent as BonecaBlackAndWhite } from '../../../assets/misc/header/p--no-colors.svg';

export default function Header({ handleCanWheel, documentSize }) {
    const [element, setElement] = useState(null);

    useEffect(() => {
        setElement(document.querySelector(".header__textos-scroll"));
    }, []);

    const colorir = () => {
        gsap.to('#moca__cabelo, #moca__braco-esquerdo, #moca__mao-esquerda, #moca__tronco, #moca__braco-direito, #moca__mao-direita', {
            fill: '#fefefe'
        });
        gsap.to('#moca__manga-esquerda, #moca__manga-direita, #moca__saia, #moca__saia-perna-direita', {
            fill: '#b48c5e'
        });
        gsap.to('.boneca__bg', {
            width: '100%',
            height: '100%',
            duration: .4,
            ease: 'expo.out',
        });
    }

    const descolorir = () => {
        gsap.to('#moca__cabelo, #moca__braco-esquerdo, #moca__mao-esquerda, #moca__tronco, #moca__braco-direito, #moca__mao-direita', {
            fill: '#2b2b2b'
        });
        gsap.to('#moca__manga-esquerda, #moca__manga-direita, #moca__saia, #moca__saia-perna-direita', {
            fill: '#040405'
        });
        gsap.to('.boneca__bg', {
            width: 0,
            height: 0
        });
    }

    const scrollText = event => {
        if (element !== null) {
            if (documentSize > 767) {
                if (Math.sign(event.deltaY) === -1) {
                    element.scrollTop -= 15;
                    handleCanWheel(false);
                } else {
                    element.scrollTop += 15;

                    const height = element.scrollHeight - element.clientHeight;
                    if (element.scrollTop === height) {
                        handleCanWheel(true);
                    }
                }
            }
        }
    }

    return(
      <div
        className="conheca__header"
        onWheel={scrollText}
      >
          <div className="header__wrapper layout__container" id="headerConheca">
              <div className="header__left" id="headerLeftConheca">
                  <h2 className="header__titulo">
                      CONHEÇA<br />
                      <span id="cohautConheca">CO-HAUT.</span>
                  </h2>
                  <div className="header__textos-scroll">
                      <p className="header__texto __big">
                          O FUTURO É O NOVO PRESENTE
                      </p>
                      <p className="header__texto header__texto--2">
                          Nossa personalidade é a nossa essência.
                      </p>
                      <p className="header__texto header__texto--3">
                          Somos inquietos, queremos mudar o jogo. A nossa vontade de fazer diferente nos levou a questionar o nosso próprio percurso: se o mundo está em constante transformação, por que nós também não?
                      </p>
                      <p className="header__texto header__texto--4">
                          Afinal, não é sobre o lugar que iremos habitar. É sobre o lugar que irá abrigar nossos sonhos e desejos mais profundos. O lugar que vai refletir o nosso estilo de vida, aquilo que acreditamos, uma extensão da nossa personalidade.
                      </p>
                      <p className="header__texto header__texto--5">
                          Assim surgiu a CO-HAUT, uma rede de empreendimentos que entrega muito além de edifícios: uma <b>comunidade</b> que apresenta às cidades uma nova forma de morar. Através da união da arquitetura com o design, trazer uma visão mais humana e sempre um passo à frente na inovação, conectando a vida em suas múltiplas dimensões.
                      </p>
                      <p className="header__texto header__texto--6">
                          Compreendendo novas formas de se relacionar com seu lar, queremos proporcionar as melhores experiências para nossos usuários através da simbiose entre o ambiente construído e toda funcionalidade que o envolve.
                      </p>
                      <p className="header__texto header__texto--7">
                          Queremos estar <b>conectados</b> com as tendências e com o mundo de possibilidades ao nosso redor. <b>Compartilhar</b> conhecimentos e experiências e ter nossa <b>mobilidade</b> garantida, acreditando que dessa forma estaremos sempre à frente do nosso tempo.
                      </p>
                  </div>
              </div>
              <div className="header__right">
                  <div className="header__boneca">
                      <div className="boneca__bg" />
                      <BonecaBlackAndWhite
                        className="boneca"
                        onMouseEnter={colorir}
                        onMouseLeave={descolorir}
                      />
                  </div>
              </div>
          </div>
      </div>
    );
}
