import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ImageModal from "../ImageModal";

import './Galeria.css';

import { ReactComponent as LeftIcon } from '../../assets/icones/chevron-left-solid.svg';
import { ReactComponent as RightIcon } from '../../assets/icones/chevron-right-solid.svg';

export default function Galeria({initialImages}) {
    const [navigation, setNavigation] = useState(null);
    const [indexActive, setIndexActive] = useState(0);
    const [images, setImages] = useState(initialImages);

    const [openModal, setOpenModal] = useState(false);
    const [fullImageSrc, setFullImageSrc] = useState(null);
    const handleModalOpen = payload => {
        setOpenModal(payload);
    }

    const exibirModal = e => {
        setFullImageSrc(e.target.src);
        setOpenModal(true);
    }

    useEffect(() => {
        gsap.to('.image__wrapper--active', {
            xPercent: 0
        });
        gsap.to('.image__wrapper--not-active', {
            xPercent: -100
        });

        const navigationLeft = document.querySelector('.galeria-cp__previous');
        const navigationRight = document.querySelector('.galeria-cp__next');
        setNavigation({
            previous: navigationLeft,
            next: navigationRight
        });
    }, []);

    useEffect(() => {
        if (navigation !== null) {
            if (indexActive !== 0) {
                navigation.previous.style.display = 'none';
                navigation.next.style.display = 'none';
            } else {
                navigation.previous.style.display = 'block';
                navigation.next.style.display = 'block';
            }
        }
    }, [indexActive]);

    const listImages = images.map((image, index) => {
        return(
          <div
            key={index}
            id={`image-wrapper-${index}`}
            className={`image__wrapper ${image.active ? 'image__wrapper--active' : 'image__wrapper--not-active'}`}
          >
              <img className="galeria-cp__image" src={image.src} alt={image.alt} onClick={exibirModal} />
          </div>
        );
    });

    const listDots = images.map((image, index) => {
        return(
          <li
            key={index}
            className={`galeria-cp__dot ${ image.active ? 'galeria-cp__dot--active' : ''}`}
            onClick={() => { jumpTo(index) }}
          >
          </li>
        );
    });

    const changeActive = (newIndex) => {
        setIndexActive(newIndex);
        setImages(() => {
            const newList = images.map((item, index) => {
                if (index === newIndex) {
                    return {...images[index], active: true}
                } else {
                    return {...images[index], active: false}
                }
            });

            return newList;
        });
    }

    const nextImage = () => {
        let oldIndex = indexActive;
        let nextIndex = indexActive + 1;
        if (nextIndex >= images.length) {
            nextIndex = 0;
        }
        changeActive(nextIndex);

        gsap.to(`#image-wrapper-${oldIndex}`, {
            xPercent: -100,
            duration: 1.3
        });

        gsap.fromTo(`#image-wrapper-${nextIndex}`, { xPercent: 100 }, { xPercent: 0, duration: 1.3 });
    }

    const previousImage = () => {
        let oldIndex = indexActive;
        let newIndex = indexActive - 1;
        if (newIndex < 0) {
            newIndex = images.length - 1;
        }
        changeActive(newIndex);

        gsap.to(`#image-wrapper-${oldIndex}`, {
            xPercent: 100,
            duration: 1.3
        });

        gsap.fromTo(`#image-wrapper-${newIndex}`, { xPercent: -100 }, { xPercent: 0, duration: 1.3 });
    }

    const jumpTo = index => {
        let oldIndex = 0;
        changeActive(index);

        for (const [index, image] of images.entries()) {
            if (image.active) {
                oldIndex = index;
            }
        }

        if (index > oldIndex) {
            gsap.to(`#image-wrapper-${oldIndex}`, {
                xPercent: -100,
                duration: 1.3
            });
            gsap.fromTo(`#image-wrapper-${index}`, { xPercent: 100 }, { xPercent: 0, duration: 1.3 });
        } else {
            gsap.to(`#image-wrapper-${oldIndex}`, {
                xPercent: 100,
                duration: 1.3
            });
            gsap.fromTo(`#image-wrapper-${index}`, { xPercent: -100 }, { xPercent: 0, duration: 1.3 });
        }
    }

    return(
      <div className="galeria-cp galeria--component">
          <ImageModal modalOpen={openModal} src={fullImageSrc} handleModalOpen={handleModalOpen} />
          <div className="galeria-cp__images">
              { listImages }
          </div>
          <ul className="galeria-cp__dots">
              { listDots }
          </ul>

          <div className="galeria-cp__indicators">
              <div className="previous__wrapper" onClick={previousImage}>
                  <LeftIcon className="galeria-cp__previous" />
              </div>
              <div className="next__wrapper" onClick={nextImage}>
                  <RightIcon className="galeria-cp__next" />
              </div>
          </div>
      </div>
    );
}
