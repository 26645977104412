import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PageIndicator from '../../../components/PageIndicator/PageIndicator.js';
import CO from '../../../assets/logos/logo-gif.png';

import 'helvatica-neue-lt/index.css';
import '../../../components/Fonts/GillSans/GillSans.js';
import './Header.css';

import Haut from '../../../assets/misc/textos/haut.png';
import Necte from '../../../assets/misc/textos/necte.png';
import Nheca from '../../../assets/misc/textos/nheca.png';

export default function Header({indicators, updateIndicatorActive}) {
  gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
       gsap.to(window, {
           scrollTo: 0,
       });
    }, []);

    useEffect(() => {
        const tl = gsap.timeline({repeat: -1});
        tl.to('.logo__texto--1', {
            display: 'block',
            duration: 1
        })
        .to('.logo__texto--1', {
            display: 'none'
        })
        .to('.logo__texto--2', {
            display: 'block',
            duration: 1,
        })
        .to('.logo__texto--2', {
            display: 'none'
        })
        .to('.logo__texto--3', {
            display: 'block',
            duration: 1
        })
        .to('.logo__texto--3', {
            display: 'none'
        })
    }, []);

   return(
      <div className="header s__parent s__home s__header" data-page="Home" data-session="Home">
        <div className="layout__container layout__container--sidebar">
            <div className="header__wrapper">
                  <div className="logo__wrapper">
                    <div
                      className="logo__container"
                    >
                        <img
                          className="header__logo"
                          src={CO} alt="CO-"
                        />
                        <div className="logo__textos">
                              <div className="logo__texto logo__texto--1"><img src={Haut} alt="Haut" /></div>
                              <div className="logo__texto logo__texto--2"><img src={Necte} alt="Nect" /></div>
                              <div className="logo__texto logo__texto--3"><img src={Nheca} alt="Nheca" /></div>
                        </div>
                     </div>
                  </div>
                  <div className="home__indicators">
                    <PageIndicator
                      pages={indicators}
                      updateIndicatorActive={updateIndicatorActive}
                    />
                  </div>
            </div>
         </div>
      </div>
   );
}