import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Magnifier from "react-magnifier";
import ImageModal from "../../../components/ImageModal";

import '../../../components/Fonts/Poppins/Poppins';
import './Galeria.css';

import { ReactComponent as LeftIcon } from '../../../assets/icones/chevron-left-solid.svg';
import { ReactComponent as RightIcon } from '../../../assets/icones/chevron-right-solid.svg';

import img1 from '../../../assets/imgs/HOME/01.jpg';
import img2 from '../../../assets/imgs/HOME/02.jpg';
import img3 from '../../../assets/imgs/HOME/03.jpg';
import img4 from '../../../assets/imgs/HOME/04.jpg';
import img5 from '../../../assets/imgs/HOME/05.jpg';
import img6 from '../../../assets/imgs/HOME/06.jpg';
import img7 from '../../../assets/imgs/HOME/07.jpg';
import img8 from '../../../assets/imgs/HOME/08.jpg';
import img9 from '../../../assets/imgs/HOME/09.jpg';
import img10 from '../../../assets/imgs/HOME/10.jpg';
import img11 from '../../../assets/imgs/HOME/11.jpg';
import img12 from '../../../assets/imgs/HOME/12.jpg';

export default function Galeria({sections, documentSize}) {
    gsap.registerPlugin(ScrollTrigger);

    const [navigation, setNavigation] = useState(null);
    const [indexActive, setIndexActive] = useState(0);
    const [images, setImages] = useState([
        { src: img1, caption: 'CO-HAUT 001 | Fachada', active: true, initial: true },
        { src: img6, caption: 'CO-HAUT 002 | Fachada', active: false },
        { src: img3, caption: 'CO-HAUT 001 | Coworking' },
        { src: img10, caption: 'CO-HAUT 002 | Terraço e piscina privativa', active: false },
        { src: img4, caption: 'CO-HAUT 001 | Área Comum', active: false },
        { src: img9, caption: 'CO-HAUT 002 | Bistrô e Pocket Livraria', active: false },
        { src: img5, caption: 'CO-HAUT 001 | Spa', active: false },
        { src: img12, caption: 'CO-HAUT 002 | Spa', active: false },
        { src: img7, caption: 'CO-HAUT 001 | Espaço Gourmet', active: false },
        { src: img8, caption: 'CO-HAUT 002 | Espaço Gourmet', active: false },
        { src: img2, caption: 'CO-HAUT 001 | Rua Compartilhada' },
        { src: img11, caption: 'CO-HAUT 002 | Fachada', active: false },
    ]);
    const [openModal, setOpenModal] = useState(false);
    const [fullImageSrc, setFullImageSrc] = useState(null);

    const handleModalOpen = payload => {
        setOpenModal(payload);
    }

    useEffect(() => {
        gsap.to('.image__wrapper--active', {
            xPercent: 0
        });
        gsap.to('.image__wrapper--not-active', {
            xPercent: -100
        });

        const navigationLeft = document.querySelector('.galeria__previous');
        const navigationRight = document.querySelector('.galeria__next');
        setNavigation({
            previous: navigationLeft,
            next: navigationRight
        });

        const images = document.querySelectorAll('.image__wrapper');
        images.forEach(image => {
           image.addEventListener('click', e => {
               setFullImageSrc(e.target.src);
               setOpenModal(true);
           });
        });
    }, []);

    useEffect(() => {
        if (navigation !== null) {
            if (indexActive !== 0) {
                navigation.previous.style.display = 'none';
                navigation.next.style.display = 'none';
            } else {
                navigation.previous.style.display = 'block';
                navigation.next.style.display = 'block';
            }
        }
    }, [indexActive]);

    const jumpTo = index => {
        let oldIndex = 0;
        changeActive(index);

        for (const [index, image] of images.entries()) {
            if (image.active) {
                oldIndex = index;
            }
        }

        if (index > oldIndex) {
            gsap.to(`#image-wrapper-${oldIndex}`, {
                xPercent: -100,
                duration: 1.3
            });
            gsap.fromTo(`#image-wrapper-${index}`, { xPercent: 100 }, { xPercent: 0, duration: 1.3 });
        } else {
            gsap.to(`#image-wrapper-${oldIndex}`, {
                xPercent: 100,
                duration: 1.3
            });
            gsap.fromTo(`#image-wrapper-${index}`, { xPercent: -100 }, { xPercent: 0, duration: 1.3 });
        }
    }

    const listImages = images.map((image, index) => {
        return(
          <div
            key={index}
            id={`image-wrapper-${index}`}
            className={`image__wrapper ${image.active ? 'image__wrapper--active' : 'image__wrapper--not-active'}`}
          >
              {
                  documentSize > 767 &&
                  <Magnifier
                    className="galeria__image"
                    src={image.src}
                    alt={image.caption}
                    mgShape='square'
                    mgShowOverflow={false}
                    mgWidth={280}
                    mgHeight={300}
                    zoomFactor={.5}
                    mgBorderWidth={8}
                    data-src={image.src}
                  />
              }
              {
                  documentSize < 768 &&
                  <div className="magnifier galeria__image">
                      <img src={image.src} alt={image.caption} data-src={image.src} height="100%" />
                  </div>
              }
              <label className="galeria__caption">{image.caption}</label>
          </div>
        );
    });

    const listDots = images.map((image, index) => {
        return(
            <li
                key={index}
                className={`galeria__dot ${ image.active ? 'galeria__dot--active' : ''}`}
                onClick={() => { jumpTo(index) }}
            >
            </li>
        );
    });

    const changeActive = (newIndex) => {
        setIndexActive(newIndex);
        setImages(() => {
            const newList = images.map((item, index) => {
                if (index === newIndex) {
                    return {...images[index], active: true}
                } else {
                    return {...images[index], active: false}
                }
            });

            return newList;
        });
    }

    const nextImage = () => {
        let oldIndex = indexActive;
        let nextIndex = indexActive + 1;
        if (nextIndex >= images.length) {
            nextIndex = 0;
        }
        changeActive(nextIndex);

        gsap.to(`#image-wrapper-${oldIndex}`, {
            xPercent: -100,
            duration: 1.3
        });

        gsap.fromTo(`#image-wrapper-${nextIndex}`, { xPercent: 100 }, { xPercent: 0, duration: 1.3 });
    }

    const previousImage = () => {
        let oldIndex = indexActive;
        let newIndex = indexActive - 1;
        if (newIndex < 0) {
            newIndex = images.length - 1;
        }
        changeActive(newIndex);

        gsap.to(`#image-wrapper-${oldIndex}`, {
            xPercent: 100,
            duration: 1.3
        });

        gsap.fromTo(`#image-wrapper-${newIndex}`, { xPercent: -100 }, { xPercent: 0, duration: 1.3 });
    }

    return (
        <div className="galeria s__galeria s__parent s__imagens s__fotos" data-page="Home" data-session="Galeria">
            <ImageModal modalOpen={openModal} src={fullImageSrc} handleModalOpen={handleModalOpen} />
            <div className="galeria__images">
                { listImages }
            </div>
            <ul className="galeria__dots">
                { listDots }
            </ul>

            <div className="galeria__indicators">
                <div className="previous__wrapper" onClick={previousImage}>
                    <LeftIcon className="galeria__previous" />
                </div>
                <div className="next__wrapper" onClick={nextImage}>
                    <RightIcon className="galeria__next" />
                </div>
            </div>
        </div>
    );
}
