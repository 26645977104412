import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import '../../../components/Fonts/Poppins/Poppins.js';
import './Conexao.css';

export default function Conexao({sections}) {
    gsap.registerPlugin(ScrollTrigger);
    const [activeSelector, setActiveSelector] = useState('.titulo__wrapper--conexao');

    useEffect(() => {
        let section = sections.find(section => section.to === '.conexao');

        if (section.active) {
            document.querySelector('.sidebar').classList.add('sidebar--dark');
        } else {
            document.querySelector('.sidebar').classList.remove('sidebar--dark');
        }
    }, [sections]);

    useEffect(() => {
        activeAnimation(activeSelector);
    }, []);

    const activeAnimation = (selector) => {
        var fontSize = 50;
        // if (selector === '.titulo__wrapper--conexao') {
        //     fontSize = 70;
        // }

        gsap.to(selector, {
            marginTop: '40px',
            marginBottom: '40px',
            duration: .7
        });
        gsap.to(selector + ' .titulo', {
            fontWeight: 600,
            opacity: 1,
            fontSize: fontSize,
            textTransform: 'uppercase',
            duration: .7,
            ease: "expo.out"
        });
        gsap.to(selector + ' .titulo__texto', {
            opacity: 1,
            display: 'block',
            duration: .7,
        });
        gsap.to(selector + ' .titulo__line', {
            width: '100vw',
            duration: .5,
        });
    }

    const desactiveAnimation = (selector) => {
        var fontSize = 40;
        // if (selector === '.titulo__wrapper--conexao') {
        //     fontSize = 60;
        // }

        gsap.to(selector, {
            marginBottom: '0px',
            marginTop: '0px',
            duration: .7
        });
        gsap.to(selector + ' .titulo', {
            fontWeight: 400,
            opacity: '.8',
            fontSize: fontSize,
            textTransform: 'lowercase',
            duration: .7,
        });
        gsap.to(selector + ' .titulo__texto', {
            opacity: 0,
            display: 'none',
            duration: .7,
        });
        gsap.to(selector + ' .titulo__line', {
            width: '200px',
            duration: .5,
        });
    }

    const mousein = (selector) => {
        desactiveAnimation(activeSelector);
        setActiveSelector(selector);
        activeAnimation(selector);
    }

    return(
        <div
            className="conexao s__conexao"
            data-page="Conheça"
            data-session="Conexão"
            data-to="conexao"
        >
            <label
                className="d-none s__tiny-text"
                data-page="Conheça"
                data-session="Conexão"
                data-key="conexao"
                data-to="conexao"
            >
                conexão conectividade comunidade compartilhamento mobilidade
            </label>
            <div className="conexao__wrapper">
                <div className="titulos" id="titulosConheca">
                    <div className="titulo__wrapper titulo__wrapper--conexao" onMouseEnter={() => { mousein('.titulo__wrapper--conexao') }}>
                        <div className="text__alling"><h2 className="titulo" id="conexaoConheca">conexão</h2></div>
                        <p className="titulo__texto" id="textoConexao">É o nosso ponto de partida. É sobre conectar pessoas e seus interesses.</p>
                        <div className="titulo__line"></div>
                    </div>

                    <div className="titulo__wrapper titulo__wrapper--conectividade" onMouseEnter={() => { mousein('.titulo__wrapper--conectividade') }}>
                        <div className="text__alling"><h2 className="titulo" id="conectividadeConheca">conectividade</h2></div>
                        <p className="titulo__texto" id="textoConectividade">Fazer da tecnologia uma ponte entre nós e um mundo de possibilidades ao nosso redor.</p>
                        <div className="titulo__line"></div>
                    </div>

                    <div className="titulo__wrapper titulo__wrapper--comunidade" onMouseEnter={() => { mousein('.titulo__wrapper--comunidade') }}>
                        <div className="text__alling"><h2 className="titulo" id="comunidadeConheca">comunidade</h2></div>
                        <p className="titulo__texto" id="textoComunidade">Mentalidade que cria conexões. Se reconhecer em algo e fazer disso um estilo de vida, ao lado de pessoas que estão na mesma sintonia.</p>
                        <div className="titulo__line"></div>
                    </div>

                    <div className="titulo__wrapper titulo__wrapper--compartilhamento" onMouseEnter={() => { mousein('.titulo__wrapper--compartilhamento') }}>
                        <div className="text__alling"><h2 className="titulo" id="compartilhamentoConheca">compartilhamento</h2></div>
                        <p className="titulo__texto" id="textoCompartilhamento">O essencial nos define, mas não nos limita. Acreditamos que compartilhando podemos ter acesso a mais e melhores experiências.</p>
                        <div className="titulo__line"></div>
                    </div>

                    <div className="titulo__wrapper titulo__wrapper--mobilidade" onMouseEnter={() => { mousein('.titulo__wrapper--mobilidade') }}>
                        <div className="text__alling"><h2 className="titulo" id="mobilidadeConheca">mobilidade</h2></div>
                        <p className="titulo__texto" id="textoMobilidade">Fazer da tecnologia uma ponte entre nós e um mundo de possibilidades ao nosso redor.</p>
                        <div className="titulo__line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}