import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Layout from '../../layout/Layout/Layout.js';
import Header from './Header';
import CoHaut from './CoHaut';
import Galeria from './Galeria';
import Empreendimentos from './Empreendimentos';
import Footer from './Footer';

import './Home.css';
import gsap from 'gsap/gsap-core';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default function Home() {
  gsap.registerPlugin(ScrollToPlugin);

  const location = useLocation();
  const history = useHistory();

  const [documentSize, setDocumentSize] = useState(null);
  const [indicators, setIndicators] = useState(
    [
      { label: 'Home', to: '.home', active: true },
      { label: 'CO-HAUT', to: '.co-haut', active: false },
      { label: 'Galeria', to: '.galeria', active: false },
      { label: 'Empreendimentos', to: '.empreendimentos', active: false },
      { label: 'Contato', to: '.footer', active: false },
    ]
  );

  useState(() => {
    setDocumentSize(document.body.clientWidth);
    window.addEventListener('resize', () => {
      setDocumentSize(document.body.clientWidth);
    });
  }, []);

  useState(() => {
    if (typeof location.state !== 'undefined') {
      if (typeof location.state.to !== 'undefined') {
        gsap.to(window, {
          scrollTo: location.state.to,
          duration: 3,
          onStart() {
            updateIndicatorActive(location.state.to);
          },
          onComplete() {
            history.replace({
              pathname: '/',
              state: {}
            });
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    let query = new URLSearchParams(location.search);
    let to = query.get("to");

    if (to !== null) {
      to = `.${to}`;
      indicators.forEach(indicator => {
        if (to === indicator.to) {
          gsap.to(window, {
            scrollTo: indicator.to,
            duration: 3,
            onStart() {
              updateIndicatorActive(indicator.to);
            },
            onComplete() {
              history.replace({
                pathname: '/',
                state: {}
              });
            }
          });
        }
      });
    }
  }, []);

  const updateIndicatorActive = to => {
    setIndicators(indicators.map(indicator => {
      if (indicator.to === to) {
        return {...indicator, active: true}
      } else {
        return {...indicator, active: false}
      }
    }));
  }

  const returnIndicators = () => {
    return indicators;
  }

  return(
    <Layout
      sections={indicators}
      topbar={{showLogo: true, showSearch: true}}
      updateIndicatorActive={updateIndicatorActive}
      updateSection={returnIndicators}
      documentSize={documentSize}
    >
      <div className="home">

        <Header indicators={indicators} updateIndicatorActive={updateIndicatorActive} />
        <CoHaut />
        <Galeria sections={indicators} documentSize={documentSize} />
        <Empreendimentos />
        <Footer />

      </div>
    </Layout>
  )
}
