import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';

import ContextMenu from '../../components/ContextMenu';
import CustomMenu from '../../components/CustomMenu';

import '../../components/Fonts/HevelticaNeue/HevelticaNeue.js';
import './Formulario.css';

import { ReactComponent as WppIcon } from '../../assets/icones/whatsapp.svg';

export default function Formulario() {

  // Menu Contexto Personalizado
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);

  const openMenu = (e, link) => {
    setMenuItems([
      {
        id: 0,
        label: 'Abrir em uma nova guia',
        action: () => {
          const win = window.open(link, '_blank');
          if (win !== null) {
            win.focus();
          }
          setOpenContextMenu(false);
        }
      }
    ]);

    setPosX(e.nativeEvent.x);
    setPosY(e.nativeEvent.y);

    setOpenContextMenu(true);
  }

  const handleOpenMenu = payload => {
    setOpenContextMenu(payload);
  }

  const [enviando, setEnviado] = useState(false);
	const [nome, setNome] = useState("");
	const [email, setEmail] = useState("");
	const [assunto, setAssunto] = useState({});
	const [mensagem, setMensagem] = useState("");

    const enviar = e => {
		e.preventDefault();

		if ('label' in assunto && 'email' in assunto) {
			setEnviado(true);

			var _mensagem = `
				<table>
					<tr>
						<th>Nome:</th>
						<td>${nome}</td>
					</tr>
					<tr>
						<th>Email:</th>
						<td>${email}</td>
					</tr>
					<tr>
						<th>Assunto:</th>
						<td>${assunto.label}</td>
					</tr>
					<tr>
						<th>Mensagem:</th>
						<td>${mensagem}</td>
					</tr>
				</table>
			`;

			const data = {
				from: assunto.email,
				to: assunto.email,
				subject: assunto.label,
				html: _mensagem
			}

			axios.post('https://api.mailer.haut.id/email/send', data)
			.then(response => {
				console.log(response);
				setEnviado(false);
			})
			.catch(err => {
				console.log(err);
				setEnviado(false);
			});
		} else {

		}
    }

    const options = [
    { value: 0, email: 'comercial@haut.id', label: 'Tenho interesse em um empreendimento HAUT' },
		{ value: 1, email: 'suprimentos@haut.id', label: 'Quero fazer uma parceria com a HAUT' },
		{ value: 2, email: 'comercial@haut.id', label: 'Tenho dúvida e outras informações sobre a HAUT' },
		{ value: 3, email: 'queroser@haut.id', label: 'Quer ser HAUT!' }
	];

    return(
        <form className="formulario-contato">
            <ContextMenu
              x={posX}
              y={posY}
              menu={<CustomMenu items={menuItems} />}
              isOpen={openContextMenu}
              handleOpenMenu={handleOpenMenu}
            />
            <div className="layout__container">
                <div className="formulario__group">
                    <input name="nome" className="formulario-contato__input" placeholder="Nome *" />
                </div>
                <div className="formulario__group">
                    <input type="email" className="formulario-contato__input" placeholder="Email *" />
                </div>
                <div className="formulario__group" style={{maxWidth: '657px'}}>
                    <Select
                        className="formulario-contato__select"
                        classNamePrefix="select"
                        placeholder="SELECIONE O ASSUNTO"
                        options={options}
                        onChange={value => { setAssunto(value) }}
                    />
                </div>

                <div className="formulario__group">
                    <textarea className="formulario-contato__textarea"></textarea>
                </div>

                <div className="formulario__group formulario__group--flex text-center">
                    <button className="formulario-contato__enviar-btn">Enviar</button>
                    <button
                        className="formulario-contato__enviar-btn formulario-contato__enviar-btn--wpp"
                        type="button"
                        onClick={ () => { document.querySelector('.link-wpp').click(); } }
                        onContextMenu={e => { openMenu(e, 'https://api.whatsapp.com/send?phone=5581982596605&text=Olá, gostaria de saber mais sobre o CO-HAUT') }}
                    >
                        <a
                            className="link-wpp"
                            target="__blank"
                            href="https://api.whatsapp.com/send?phone=5581982596605&text=Olá, gostaria de saber mais sobre o CO-HAUT"
                            onContextMenu={e => { openMenu(e, 'https://api.whatsapp.com/send?phone=5581982596605&text=Olá, gostaria de saber mais sobre o CO-HAUT') }}
                        >
                            <WppIcon className="wppicon" /> WhatsApp
                        </a>
                    </button>
                </div>
            </div>
        </form>
    );
}
