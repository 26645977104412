import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from './pages/home/Home.js'
import Contato from './pages/contato/Contato.js'
import Conheca from './pages/conheca/Conheca.js'
import Comunidade from "./components/Pictures/Pictures.js";
import Formulario from "./components/Formulario/formulario.js";
import COHAUT001 from './pages/empreendimentos/COHAUT-001';
import COHAUT002 from './pages/empreendimentos/COHAUT-002';
import COHAUT003 from './pages/empreendimentos/COHAUT-003';

export default function Router() {
    return (
        <BrowserRouter>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/contato" component={Contato}></Route>
            <Route exact path="/conheca" component={Conheca}></Route>
            <Route exact path="/empreendimentos/COHAUT-001" component={COHAUT001} />
            <Route exact path="/empreendimentos/COHAUT-002" component={COHAUT002} />
            <Route exact path="/empreendimentos/COHAUT-003" component={COHAUT003} />
            <Route exact path="/pictures/comunidade/" component={Comunidade}></Route>
            <Route exact path="/formulario" component={Formulario}></Route>
        </BrowserRouter>
    )
}
