import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar.js';
import Topbar from '../Topbar/Topbar.js';
import LoadRoute from '../../components/LoadRoute';
//import MouseWatcher from '../../components/MouseWatcher';
import Navegator from '../../components/Navegator';

import './Layout.css'


export default function Layout({
    children,
    sections,
    topbar,
    updateIndicatorActive,
    changeWheeling,
    canWheel,
    documentSize
}) {
    const [isLoad, setIsLoad] = useState(true);
    const [wheeling, setWheeling] = useState(false);
    const [_canWheel, setCanWheel] = useState(true);
    const [touchMap, setTouchMap] = useState({x: 0, y: 0});

    useEffect(() => {
        const eventPrevent = e => e.preventDefault();
        document.addEventListener('contextmenu', eventPrevent);
        // return document.removeEventListener('contextmenu', eventPrevent);
    });

    useEffect(() => {
        if (canWheel !== undefined) {
            setCanWheel(canWheel);
        }
    }, [canWheel]);

    useEffect(() => {
        setWheeling(changeWheeling);
    }, [changeWheeling]);

    useEffect(() => {
        document.addEventListener("keyup", function(e) {
            if (e.ctrlKey && (e.code === "Home" || e.key === "Home")) {
                let to = sections[0].to;
                Navegator.to(to);
                updateIndicatorActive(to);
            }
            if (e.ctrlKey && (e.code === "End" || e.key === "End")) {
                let to = sections[sections.length - 1].to;
                Navegator.to(to);
                updateIndicatorActive(to);
            }
        });
    }, []);

    const loaded = () => {
        setIsLoad(!isLoad);
    }

    const ifWheel = event => {
        if (!wheeling && _canWheel && documentSize > 767) {
            if (Math.sign(event.deltaY) === -1) {
                let anchor = document.querySelector('#sidebar-up-click');
                if (anchor !== null) {
                    anchor.click();
                }
            } else {
                let anchor = document.querySelector('#sidebar-down-click');
                if (anchor !== null) {
                    anchor.click();
                }
            }
        }
    }

    const touchStart = event => {
        setTouchMap({
            x: event.touches[0].pageX,
            y: event.touches[0].pageY
        });
    }

    const ifTouch = event => {
        let offset = {};

        offset.x = touchMap.x - event.touches[0].pageX;
        offset.y = touchMap.y - event.touches[0].pageY;

        if (offset.y > 100) {
            let anchor = document.querySelector('#sidebar-down-click');
            if (anchor !== null) {
                anchor.click();
            }
        } else if (offset.y < -100) {
            let anchor = document.querySelector('#sidebar-up-click');
            if (anchor !== null) {
                anchor.click();
            }
        }
    }

    const _changeWheeling = payload => {
        setWheeling(payload);
    }

    return(
        <div
            className="layout"
            onWheel={ifWheel}
        >
            <LoadRoute showLoad={isLoad} onComplete={loaded} />
            <Sidebar
                sections={sections}
                updateIndicatorActive={updateIndicatorActive}
                changeWheeling={_changeWheeling}
                wheeling={wheeling}
            />

            <div className="content__wrapper">
                <div className="content">
                    <Topbar
                        showLogo={topbar.showLogo}
                        showSearch={topbar.showSearch}
                        updateSection={updateIndicatorActive}
                    />
                    { children }
                </div>
            </div>
        </div>
    )
}
