import React from 'react';
import './formulario.css';

export default function Formulario() {
    return (
        <div className="background">
            <div className="container d-flex justify-content-center">
                <div id="form-cadastro" className="text-center">
                    <form size='large' className="formulario">
                        <input className="inputText" type="text" id="nome" placeholder="  NOME: *" required aria-required="true"></input>
                        <input className="inputText" type="email" id="email" placeholder="  EMAIL: *" required aria-required="true"></input>
                        <select className="inputText">
                            <option>SELECIONE O ASSUNTO</option>
                            <option value="comercial@haut.id">Tenho interesse em um empreendimento HAUT</option>
                            <option value="suprimentos@haut.id">Quero fazer uma parceria com a HAUT</option>
                            <option value="comercial@haut.id">Tenho dúvida e outras informações sobre a HAUT</option>
                            <option value="queroser@haut.id">Quer ser HAUT!</option>
                        </select>
                        <textarea className="inputMensagem" type="text" required aria-required="true" placeholder=" MENSAGEM:"></textarea>
                        <button type="submit" className="btn-submit">ENVIAR</button>
                    </form>
                </div>
            </div>
        </div>
    )
}