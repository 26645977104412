import React from 'react';
import GaleriaComponent from '../../../components/Galeria';

import './Galeria.css';

export default function Galeria({images, page}) {
	return(
		<div
			className="galeria galeria--empreendimento s__galeria s__imagens s__fotos"
			data-page={page}
			data-section="Galeria"
		>
			<div className="galeria__wrapper">
				<GaleriaComponent className="galeria-component--empreendimento" initialImages={images} />
			</div>
		</div>
	);
}