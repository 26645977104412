import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './AreaComum.css';

export default function AreaComum({
	inLeft,
	inRight,
	images,
	title,
	subtitle,
	page
}) {

	// Left to Right
	const [ltr, setLtr] = useState(true);

	useEffect(() => {
		if (typeof inRight !== 'undefined') {
			if (inRight) {
				setLtr(false);
			}
		}
	}, [inRight]);

	const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

	const listImages = images.map((image, index) => {
		return(
			<div
				key={index}
				className="area-comum__image-wrapper"
			>
				<img
					className="area-comum__image"
					src={image.src}
					alt={ image.alt ? image.alt : 'Area Comum' }
				/>
			</div>
		);
	});

	return(
		<div className="layout__container">
			<div
				className="area-comum s__area-comum s__fotos s__imagens"
				data-page={page}
				data-section="Área Comum"
			>
				<div
					className={`
						area-comum__wrapper
						${!ltr ? 'area-comum__wrapper--reverse' : ''}
					`}
				>
					<div
						className={`
							area-comum__content
							${!ltr ? 'area-comum__content--reverse' : ''}
						`}
					>
						<h2 className="area-comum__titulo">{title}</h2>
						<p className="area-comum__texto">{subtitle}</p> 
					</div>
					<div className="area-comum__galeria">
						<Slider {...settings}>
							{ listImages }
						</Slider>
					</div>
				</div>
			</div>
		</div>
	);
}