import React, { useState, useEffect } from 'react';

import './Informacoes.css';

export default function Informacoes({address, types, units, title, subtitle, text, page, handleCanWheel}) {
  const [element, setElement] = useState(null);

  useEffect(() => {
    setElement(document.querySelector(".informacoes__sobre"));
  }, []);

  const scrollText = event => {
    if (element !== null) {
      if (Math.sign(event.deltaY) === -1) {
        element.scrollTop -= 15;
      } else {
        element.scrollTop += 15;
      }

      const height = element.scrollHeight - element.clientHeight;
      if (element.scrollTop === height || element.scrollTop === 0) {
        handleCanWheel(true);
      } else {
        handleCanWheel(false);
      }
    }
  }

  return(
    <div
      className="layout__container"
      onWheel={scrollText}
    >
      <div
        className="informacoes s__parent"
        data-page={page}
        data-session="Informações"
        data-to="informacoes"
      >
        <label
          className="d-none s__tiny-text"
          data-page={page}
          data-session="Informações"
          data-key="informacoes"
          data-to="informacoes"
        >
          endereço endereco rua cidade bairro tamanho altura localizacao localização area área
        </label>
        {/* Desktop */}
        <div className="informacoes__endereco">
          <div className="endereco__linha">
            <div
              className="endereco__coluna endereco__subtitulo s__tiny-text"
              data-page={page}
              data-session="Informações"
              data-key="informacoes"
              data-to="informacoes"
            >
              {address.city} - {address.district}
            </div>
            <div
              className="endereco__coluna endereco__subtitulo s__tiny-text"
              data-page={page}
              data-session="Informações"
              data-key="informacoes"
              data-to="informacoes"
            >
              Tipos
            </div>
            <div
              className="endereco__coluna endereco__subtitulo"
              data-page={page}
              data-session="Informações"
              data-key="informacoes"
              data-to="informacoes"
            >
              Unidades
            </div>
          </div>
          <div className="endereco__separador" />
          <div className="endereco__linha">
            <div className="endereco__coluna endereco__titulo">{address.street}, {address.number}</div>
            <div className="endereco__coluna endereco__titulo">{types}</div>
            <div className="endereco__coluna endereco__titulo">{units}</div>
          </div>
          <div className="endereco__separador" />
        </div>
        {/* Mobile */}
        <div className="informacoes__endereco informacoes__endereco--mobile">
          <div className="endereco__linha endereco__linha--mobile">
            <p className="endereco__subtitulo">{address.city} - {address.district}</p>
            <h3 className="endereco__titulo">{address.street}, {address.number}</h3>
          </div>
          <div className="endereco__linha endereco__linha--mobile">
            <p className="endereco__subtitulo">Tipos</p>
            <h3 className="endereco__titulo">{types}</h3>
          </div>
          <div className="endereco__linha endereco__linha--mobile">
            <p className="endereco__subtitulo">Unidades</p>
            <h3 className="endereco__titulo">{units}</h3>
          </div>
        </div>
        <div className="informacoes__sobre">
          <h2 className="sobre__titulo">{title}</h2>
          <h3
            className="sobre__subtitulo s__tiny-text"
            data-page={page}
            data-session="Informações"
            data-key="informacoes"
            data-to="informacoes"
          >
            {subtitle}
          </h3>
          <p
            className="sobre__texto s__long-text"
            data-page={page}
            data-session="Informações"
            data-key="informacoes"
            data-to="informacoes"
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}
