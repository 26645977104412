import React from 'react';

import './ImageModal.css';

export default function ImageModal({src, modalOpen, handleModalOpen}) {
  return (
    <>
      {
        modalOpen &&
        <div className="image-modal" onClick={() => handleModalOpen(false)}>
          <div className="image-modal__wrapper">
            <div className="image-modal__inner" onClick={e => e.stopPropagation()}>
              <p className="image-modal__close" onClick={() => handleModalOpen(false)}>&times;</p>
              <img className="image-modal__image" src={src} alt="Imagem"/>
            </div>
          </div>
        </div>
      }
    </>
  );
}
