import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Layout from '../../layout/Layout/Layout.js';
import './Conheca.css';
import Header from './Header'
import SecConheca02 from './SecConheca02/SecConheca02.js'
import Conexao from './Conexao/Conexao.js';
import Equipe from './Equipe/Equipe.js';


export default function Conheca() {
  gsap.registerPlugin(ScrollToPlugin);

  const [documentSize, setDocumentSize] = useState(null);

  useEffect(() => {
    gsap.to(window, {
      scrollTo: 0
    });
  }, []);

  useState(() => {
    setDocumentSize(document.body.clientWidth);
    window.addEventListener('resize', () => {
      setDocumentSize(document.body.clientWidth);
    });
  }, []);

  const [canWheel, setCanWheel] = useState(false);
  const [indicators, setIndicators] = useState(
      [
          { label: 'Conheça', to: '.conheca', active: true },
          { label: 'Sessão 02', to: '.div-conheca02', active: false },
          { label: 'Conexão', to: '.conexao', active: false },
          { label: 'Equipe', to: '.equipe', active: false },
      ]
  );

  const updateIndicatorActive = to => {
      setIndicators(indicators.map(indicator => {
          if (indicator.to === to) {
              return {...indicator, active: true}
          } else {
              return {...indicator, active: false}
          }
      }));
  }

  const returnIndicators = () => {
      return indicators;
  }

  const handleCanWheel = payload => {
    setCanWheel(payload);
  }

  return (
    <Layout
      sections={indicators}
      topbar={{showLogo: false, showSearch: false}}
      updateIndicatorActive={updateIndicatorActive}
      updateSection={returnIndicators}
      canWheel={canWheel}
      documentSize={documentSize}
    >
      <div className="conheca">
        <Header
          handleCanWheel={handleCanWheel}
          documentSize={documentSize}
        />
        <SecConheca02 sections={indicators}></SecConheca02>
        <Conexao sections={indicators} />
        <Equipe sections={indicators} changeSection={updateIndicatorActive} />
      </div>
    </Layout>
  );
}
