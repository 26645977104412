import React from 'react';
import Layout from '../../layout/Layout/Layout.js';
import Formulario from './Formulario.js';
import '../../components/Fonts/Poppins/Poppins.js';

import './Contato.css';
import logo from '../../assets/logos/co.png';
import phone from '../../assets/icones/phone.png';
export default function Contato() {

    const sections = [
        { label: 'Contato', to: '.contato__wrapper' }
    ];

    return(
        <Layout
            sidebar={{showArrowUp: false, showArrowDown: false}}
            topbar={{showLogo: false, showSearch: false}}
            sections={sections}
        >
            <div
                className="contato__wrapper"
                data-page="Contato"
                data-session="Formulário de Contato"
                data-to="contato"
            >

                <label
                    className="d-none s__tiny-text"
                    data-page="Contato"
                    data-session="Formulário de Contato"
                    data-key="contato"
                    data-to="contato"
                >
                    Contato Formulário de Contato formulario
                </label>

                <img src={logo} className="contato__logo contato__logo--left" alt="CO-" />
                <img src={logo} className="contato__logo contato__logo--right" alt="HAUT" />

                <div className="contato__content">
                    <div className="content__top">
                        <Formulario />
                    </div>
                    <div className="content__bottom">
                        <div className="layout__container">
                            <p className="text-center text-white" id="cttTel"><img src={phone} alt="Telefone" /> 55 (81) 3031-6875</p>
                            <p className="text-center text-white" id="cttEnd">HAUT Incorporadora Design, JCPM Trade Center, Av. Antônio de Góes, 60<br />Salas 903 e 904, Pina - CEP 51010-000</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}