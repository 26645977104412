import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import FooterComponent from '../../../components/Footer';

import '../../../components/Fonts/HevelticaNeue/HevelticaNeue.js';
import './Footer.css';

import { ReactComponent as Whatsapp } from '../../../assets/icones/whatsapp.svg'

mapboxgl.accessToken = 'pk.eyJ1IjoiaGF1dCIsImEiOiJja2VsZmx4NnIwOTdzMnBvNHB2YmNwaHJqIn0.Lwh8rQGLX0nkcM0h_AAWTQ';

export default function Footer({sections, changeSection, page, number, coords}) {

	const [enviando, setEnviado] = useState(false);
	const [nome, setNome] = useState("");
	const [email, setEmail] = useState("");
	const [assunto, setAssunto] = useState({});
	const [mensagem, setMensagem] = useState("");

	const enviar = e => {
		e.preventDefault();

		if ('label' in assunto && 'email' in assunto) {
			setEnviado(true);

			var _mensagem = `
				<table>
					<tr>
						<th>Nome:</th>
						<td>${nome}</td>
					</tr>
					<tr>
						<th>Email:</th>
						<td>${email}</td>
					</tr>
					<tr>
						<th>Assunto:</th>
						<td>${assunto.label}</td>
					</tr>
					<tr>
						<th>Mensagem:</th>
						<td>${mensagem}</td>
					</tr>
				</table>
			`;

			const data = {
				from: assunto.email,
				to: assunto.email,
				subject: assunto.label,
				html: _mensagem
			}

			axios.post('https://api.mailer.haut.id/email/send', data)
			.then(response => {
				console.log(response);
				setEnviado(false);
			})
			.catch(err => {
				console.log(err);
				setEnviado(false);
			});
		} else {

		}
	}

	useEffect(() => {
		const map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/light-v10',
			center: coords,
			zoom: 15.5,
			pitch: 45,
			bearing: -17.6,
			antialias: true,
		});

		// Add zoom and rotation controls to the map.
		map.addControl(new mapboxgl.NavigationControl());

		// The 'building' layer in the mapbox-streets vector source contains building-height
		// data from OpenStreetMap.
		map.on('load', function() {
		// Insert the layer beneath any symbol layer.
		var layers = map.getStyle().layers;

		var labelLayerId;
		for (var i = 0; i < layers.length; i++) {
			if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
				labelLayerId = layers[i].id;
				break;
			}
		}

		map.addLayer(
			{
				'id': '3d-buildings',
				'source': 'composite',
				'source-layer': 'building',
				'filter': ['==', 'extrude', 'true'],
				'type': 'fill-extrusion',
				'minzoom': 15,
				'paint': {
					'fill-extrusion-color': '#aaa',

					// use an 'interpolate' expression to add a smooth transition effect to the
					// buildings as the user zooms in
					'fill-extrusion-height': [
					'interpolate',
					['linear'],
					['zoom'],
					15,
					0,
					15.05,
					['get', 'height']
					],
					'fill-extrusion-base': [
					'interpolate',
					['linear'],
					['zoom'],
					15,
					0,
					15.05,
					['get', 'min_height']
					],
					'fill-extrusion-opacity': 0.6
				}
			},
			labelLayerId
		);

		var marker = new mapboxgl.Marker()
			.setLngLat(coords)
			.addTo(map);
	});


	}, []);

	const options = [
        { value: 0, email: 'comercial@haut.id', label: 'Tenho interesse em um empreendimento HAUT' },
		{ value: 1, email: 'suprimentos@haut.id', label: 'Quero fazer uma parceria com a HAUT' },
		{ value: 2, email: 'comercial@haut.id', label: 'Tenho dúvida e outras informações sobre a HAUT' },
		{ value: 3, email: 'queroser@haut.id', label: 'Quer ser HAUT!' }
	];

	const footerLink = {
        text: 'Ver mais empreendimentos',
        to: '.empreendimentos',
        uri: '/'
    }

	return(
		<div className="position-relative">
			<div className="layout__container">
				<div
					className="e-footer s__contato s__mapa s__endereco"
					data-page={page}
					data-section="Contato"
				>
					<div className="e-footer__row">
						<div className="e-footer__column e-footer__column--2">
							<div className="image__wrapper">
								<div className="e-footer__image">
									<div id="map" />
								</div>
							</div>
						</div>
						<div className="e-footer__column e-footer__column--1">
							<div className="e-footer__formulario">
								<div className="formulario__header">
									<p className="formulario__title">MANDE UMA MENSAGEM</p>
									<div className="co-haut__wrapper">
										<p className="co-haut__titulo">CO-HAUT</p>
										<p className="co-haut__number">{number}</p>
									</div>
								</div>

								<div className="formulario__body">
									<form className="formulario-contato">
										<div className="formulario__group">
											<input onChange={e => { setNome(e.target.value) }} name="nome" className="formulario-contato__input" placeholder="Nome *" />
										</div>
										<div className="formulario__group">
											<input onChange={e => { setEmail(e.target.value) }} type="email" className="formulario-contato__input" placeholder="Email *" />
										</div>
										<div className="formulario__group">
											<Select
												className="formulario-contato__select"
												classNamePrefix="select"
												placeholder="SELECIONE O ASSUNTO"
												options={options}
												onChange={value => { setAssunto(value) }}
											/>
										</div>

										<div className="formulario__group">
											<textarea onChange={e => { setMensagem(e.target.value) }} className="formulario-contato__textarea"></textarea>
										</div>

										<div className="formulario__group">
											<div className="formulario__buttons">
												<button onClick={enviar} disabled={enviando} className="formulario-contato__enviar-btn" type="submit">Enviar</button>
												<button className="formulario-contato__enviar-btn">
													<Whatsapp className="formulario__wpp-icon" />
													Whatsapp
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
			<FooterComponent link={footerLink} sections={sections} changeSection={changeSection} />
		</div>
	)
}
