import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import ContextMenu from '../../../components/ContextMenu';
import CustomMenu from '../../../components/CustomMenu';

import './CoHaut.css';
import C from '../../../assets/logos/c.png';
import logo from '../../../assets/logos/logo-white.png';

export default function CoHaut() {
    gsap.registerPlugin(ScrollTrigger);

    const history = useHistory();

    // Menu Contexto Personalizado
    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const [lineTopCoords, setLineTopCoords] = useState({x: 0, y: 0});
    const [lineBottomCoords, setLineBottomCoords] = useState({x: 0, y: 0});

    const openMenu = (e, link) => {
      setMenuItems([
        {
          id: 0,
          label: 'Abrir em uma nova guia',
          action: () => {
            const win = window.open(link, '_blank');
            if (win !== null) {
              win.focus();
            }
            setOpenContextMenu(false);
          }
        }
      ]);

      setPosX(e.nativeEvent.x);
      setPosY(e.nativeEvent.y);

      setOpenContextMenu(true);
    }

    const handleOpenMenu = payload => {
      setOpenContextMenu(payload);
    }

    useEffect(() => {
        let texto = document.querySelector('.co-haut__texto--top');
        let c = document.querySelector('.c__image');

        let textoClient = texto.getBoundingClientRect();
        let cClient = c.getBoundingClientRect();

        let x = Math.abs(textoClient.left - cClient.left);
        let xTop = Math.abs(x - (cClient.width - textoClient.width));

        setLineTopCoords({x: xTop});
        setLineBottomCoords({x});
    }, [])

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.co-haut',
                toggleActions: 'restart'
            }
        });
        tl.from('.c__image', {
            xPercent: 100,
            rotate: 360,
            duration: 2
        });
        tl.from('.co-haut__line', {
            width: 0,
            duration: 2
        });
    }, []);

    const fillButton = () => {
        gsap.to('.mouse-watcher', {
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            opacity: 0,
            backgroundColor: '#fff'
        });

        gsap.to('.button__background', {
            width: '45%',
            border: '1.5px solid #fff',
            onStart: () => {
                let cButton = document.querySelector('.c__button');
                if (cButton !== null) {
                    cButton.style.color = '#000';
                }
            }
        });
    }

    const resetButton = () => {
        gsap.to('.mouse-watcher', {
            opacity: 1,
            backgroundColor: '#000',
            top: '-100px',
            left: '-100px',
            width: '200px',
            height: '200px',
        });

        gsap.to('.button__background', {
            width: 0,
            border: 'none',
            onStart: () => {
                document.querySelector('.c__button').style.color = '#fff';
            }
        });
    }

    return (
        <div className="co-haut s__parent s__co-haut" data-page="Home" data-session="CO-HAUT">
            <ContextMenu
              x={posX}
              y={posY}
              menu={<CustomMenu items={menuItems} />}
              isOpen={openContextMenu}
              handleOpenMenu={handleOpenMenu}
            />
            <div className="layout__container">
                <div className="co-haut__wrapper">
                    <div className="co-haut__left">
                        <div className="co-haut__header">
                            <div className="co-haut__titulo">
                                <img src={logo} alt="CO-HAUT" />
                            </div>
                        </div>
                        <div className="co-haut__body">
                            <div className="co-haut__line co-haut__line--top" />
                            <div className="co-haut__line co-haut__line--bottom" />
                            <p
                                className="co-haut__texto co-haut__texto--top s__long-text"
                                data-page="Home"
                                data-session="CO-HAUT"
                                data-key="cohaut-text"
                            >
                                Suas escolhas dizem muito sobre você.
                            </p>
                            <p
                                className="co-haut__texto co-haut__texto--center s__long-text"
                                data-page="Home"
                                data-session="CO-HAUT"
                                data-key="cohaut-text"
                            >
                                Nós já não trabalhamos como antes, não nos locomovemos como antes, não nos relacionamos e, muito em breve, não iremos nos alimentar como antes…
                            </p>
                            <p
                                className="co-haut__texto co-haut__texto--bottom s__long-text"
                                data-page="Home"
                                data-session="CO-HAUT"
                                data-key="cohaut-text"
                            >
                                Por que continuar morando como antes?
                            </p>
                        </div>
                    </div>
                    <div className="co-haut__right">
                        <div
                            className="c__wrapper"
                        >
                            <img src={C} className="c__image" alt="C Gigante" />
                            <div
                                className="c__button__wrapper"
                            >
                                <button
                                    className="c__button s__tiny-text"
                                    data-page="Home"
                                    data-session="CO-HAUT"
                                    data-key="cohaut-text"
                                    onMouseEnter={() => { fillButton() }}
                                    onMouseLeave={() => { resetButton() }}
                                    onClick={() => { history.push('/conheca') }}
                                    onContextMenu={e => { openMenu(e, '/conheca') }}
                                >
                                  CONHEÇA
                                </button>
                                <div className="button__background"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
