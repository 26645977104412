import React, { useEffect } from 'react';
import './Videos.css';

export default function Videos({play}) {

    useEffect(() => {
        if (play !== null) {
            const video = document.querySelector(".box-video");
            if (play) {
                video.play();
            } else {
                video.pause();
            }
        }
    }, [play]);

    return (
        <div className="box-video-comp">
            <video className="box-video" loop controls muted>
                <source src={require('../../assets/cohaut001-video.mp4')} type="video/mp4"></source>
            </video>
        </div>
    )
}
