import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';

import './LoadRoute.css';
import logo from '../../assets/logos/logo-cohaut-white.svg';

export default function LoadRoute({ showLoad, onComplete }) {
    const [inAnimation, setInAnimation] = useState(false);

    useEffect(() => {
        if (!inAnimation) {
            const load = document.querySelector('.load');

            load.style.display = 'flex';
            setInAnimation(true);
            gsap.to('.load', {
                opacity: 1,
                onComplete() {
                    gsap.to('.load', {
                        opacity: 0,
                        duration: 2,
                        ease: 'expo.out',
                        onComplete() {
                            onComplete();
                            load.style.display = 'none';
                            setInAnimation(false);
                        }
                    });
                }
            });
        }
    }, [showLoad]);

    return(
        <div className="load">
			<img className="load__image" src={logo} alt="carregando..." />
		</div>
    );
}