import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import './FullMenu.css';
import logo from '../../assets/logos/logo-cohaut-white.svg';
import { ReactComponent as Times} from '../../assets/icones/close.svg';

export default function FullMenu({ open, closeMenu }) {
    gsap.registerPlugin(ScrollToPlugin);
    const history = useHistory();
    const location = useLocation();

    const [hasTrasition, setHasTrasition] = useState(false);

    const onMouseEnter = triggerClass => {
        gsap.to(triggerClass, {
            fontSize: '3.2rem',
            color: '#fff',
            duration: .3
        });
        gsap.to(`${triggerClass} .item__underline`, {
            width: '100%',
            duration: .3
        });
    }

    const mouseOut = triggerClass => {
        gsap.to(triggerClass, {
            fontSize: '2.4rem',
            color: '#555555',
            duration: .3
        });
        gsap.to(`${triggerClass} .item__underline`, {
            width: 0,
            duration: .3
        });
    }

    useEffect(() => {
        if (open) {
            if (!hasTrasition) {
                const tl = gsap.timeline({
                    onStart: () => {
                        setHasTrasition(true);
                    },
                    onComplete: () => {
                        setHasTrasition(false);
                    }
                });
                tl.to('.fullmenu--open', {
                    display: 'block',
                    height: '100vh',
                    duration: 1
                });
                tl.to('.fullmenu--open .fullmenu__item', {
                    opacity: 1,
                    duration: .5
                });
                tl.to('.fullmenu--open .fullmenu__logo', {
                    opacity: 1,
                    duration: .5
                });
                tl.to('.fullmenu--open .fullmenu__close', {
                    opacity: 1,
                    duration: .5
                });
            }
        } else {
            if (!hasTrasition) {
                const tl = gsap.timeline({
                    onStart: () => {
                        setHasTrasition(true);
                    },
                    onComplete: () => {
                        setHasTrasition(false);
                        closeMenu(false);
                    }
                });
                tl.to('.fullmenu .fullmenu__close', {
                    opacity: 0,
                    duration: .5
                });
                tl.to('.fullmenu .fullmenu__logo', {
                    opacity: 0,
                    duration: .5
                });
                tl.to('.fullmenu .fullmenu__item', {
                    opacity: 0,
                    duration: .5
                });
                tl.to('.fullmenu', {
                    display: 'none',
                    height: 0,
                    duration: 1
                });
            }
        }
    }, [open]);

    const changePage = uri => {
        if (hasTrasition) {
            return;
        }
        
        if (location.pathname === uri) {
            gsap.to(window, {
                scrollTo: 0
            });
            closeMenu(false);
        } else if (uri[0] === '.') {
            if (location.pathname === '/') {
                    gsap.to(window, {
                    scrollTo: uri
                });
                closeMenu(false);
            } else {
                history.push('/', { to: '.empreendimentos' });
            }
        } else {
            history.push(uri);
        }
    }

    return (
        <div className={`fullmenu ${open ? 'fullmenu--open' : ''}`}>
            <div className="fullmenu__wrapper">
                <div className="fullmenu__close" onClick={ () => { closeMenu(false) } }><Times /></div>
                <ul className="fullmenu__menu">
                    <li
                        className="fullmenu__item fullmenu__item--home"
                        onMouseEnter={() => { onMouseEnter('.fullmenu__item--home'); }}
                        onMouseLeave={() => { mouseOut('.fullmenu__item--home'); }}
                        onClick={ () => { changePage('/') }}
                    >
                        Home
                        <div className="item__underline"></div>
                    </li>
                    <li
                        className="fullmenu__item fullmenu__item--conheca"
                        onMouseEnter={() => { onMouseEnter('.fullmenu__item--conheca') }}
                        onMouseLeave={() => { mouseOut('.fullmenu__item--conheca') }}
                        onClick={() => { changePage('/conheca'); }}
                    >
                        Conheça
                        <div className="item__underline"></div>
                    </li>
                    <li
                        className="fullmenu__item fullmenu__item--empreendimentos"
                        onMouseEnter={() => { onMouseEnter('.fullmenu__item--empreendimentos') }}
                        onMouseLeave={() => { mouseOut('.fullmenu__item--empreendimentos') }}
                        onClick={() => { changePage('.empreendimentos') }}
                    >
                        Empreendimentos
                        <div className="item__underline"></div>
                    </li>
                    <li
                        className="fullmenu__item fullmenu__item--contato"
                        onMouseEnter={() => { onMouseEnter('.fullmenu__item--contato') }}
                        onMouseLeave={() => { mouseOut('.fullmenu__item--contato') }}
                        onClick={() => { changePage('/contato'); }}
                    >
                        Contato
                        <div className="item__underline"></div>
                    </li>
                </ul>
                <img src={logo} className="fullmenu__logo" alt="CO-HAUT" />
            </div>
        </div>
    );

}
