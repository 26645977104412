import React, { useEffect, useState } from 'react';
import './SecConheca02.css';
import Video from '../../../components/Videos/Videos.js'
import persona from '../../../assets/background/persona-video.svg'

export default function SecConheca02({sections}) {
    const [play, setPlay] = useState(null);

    useEffect(() => {
        for (let section of sections) {
            if (section.to === ".div-conheca02" && section.active) {
                setPlay(true);
                break;
            } else {
                setPlay(false);
            }
        }
    }, [sections]);

    return (
        <section
            className="div-conheca02"
            data-page="Conheça"
            data-section="Video"
            data-to="div-conheca02"
        >
            <label
                className="d-none s__tiny-text"
                data-page="Conheça"
                data-session="Vídeo"
                data-to="div-conheca02"
                data-key="conheca"
            >
                Video Vídeo Conheça 
            </label>
            <div className="conheca02-bg"></div>
            <div className="box-tudo-conheca02">
                <div className="box-video-conheca02">
                    <Video play={play}></Video>
                </div>
                {/* <div className="box-imagem-conheca02">
                    <img className="detalhes-conheca02" src={persona} alt="PERSONA CONHECA SEC02"></img>
                </div> */}
            </div>
        </section>
    )
}