import React from 'react';
import './CustomMenu.css';

export default function CustomMenu({items}) {
  return(
    <ul className="context-menu__menu">
      { items.map(item => {
        return(
          <li
            key={item.id}
            className="context-menu__item"
            onClick={item.action}
          >
            {item.label}
          </li>
        );
      }) }
    </ul>
  );
}
