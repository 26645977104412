import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../../../components/Footer';

import ContextMenu from '../../../components/ContextMenu';
import CustomMenu from '../../../components/CustomMenu';

import '../../../components/Fonts/Poppins/Poppins.js';
import './Equipe.css';
import fotoEquipe from '../../../assets/imgs/equipe.jpg';

export default function Equipe({sections, changeSection}) {
    const history = useHistory();

    // Menu Contexto Personalizado
    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    const openMenu = (e, link) => {
      setMenuItems([
        {
          id: 0,
          label: 'Abrir em uma nova guia',
          action: () => {
            const win = window.open(link, '_blank');
            if (win !== null) {
              win.focus();
            }
            setOpenContextMenu(false);
          }
        }
      ]);

      setPosX(e.nativeEvent.x);
      setPosY(e.nativeEvent.y);

      setOpenContextMenu(true);
    }

    const handleOpenMenu = payload => {
      setOpenContextMenu(payload);
    }

    const footerLink = {
        text: 'Entre em contato',
        to: '.contato',
        uri: '/contato'
    }

    return(
        <div
            className="equipe s__equipe"
            data-page="Conheça"
            data-section="Equipe"
        >
            <ContextMenu
              x={posX}
              y={posY}
              menu={<CustomMenu items={menuItems} />}
              isOpen={openContextMenu}
              handleOpenMenu={handleOpenMenu}
            />
            <label
                className="d-none s__tiny-text"
                data-page="Conheça"
                data-session="Equipe"
                data-key="equipe"
                data-to="equipe"
            >
                equipe time
            </label>
            <div className="equipe__wrapper">
                <div className="equipe__left">
                    <div className="imagem__wrapper">
                        <img className="equipe__foto" src={fotoEquipe} alt="nossa equipe reunida" />
                        <div className="equipe__efeito-foto"></div>
                    </div>
                </div>
                <div className="equipe__right">
                    <h2 className="equipe__titulo">
                        <span id="designHaut">DESIGNED<br /></span>
                        <span id="equipeHaut">BY HAUT</span>
                        <span id="pontoHaut">.</span>
                        <label
                            className="d-none s__tiny-text"
                            data-page="Conheça"
                            data-session="Equipe"
                            data-key="equipe"
                        >
                            Designed by HAUT
                        </label>
                    </h2>
                    <div className="textEquipe">
                    <p
                        className="equipe__texto s__long-text"
                        data-page="Conheça"
                        data-session="Equipe"
                        data-key="equipe"
                    >
                        Pra gente, normal é pensar diferente. Faz parte da nossa essência perguntar “por que não”. Questionar “por que sim”. Por que repetir fórmulas antigas? Só nos interessa impactar positivamente. Provocar com novos conceitos e experiências, evoluir, surpreender, superar expectativas.
                    </p>

                    <p
                        className="equipe__texto s__long-text"
                        data-page="Conheça"
                        data-session="Equipe"
                        data-key="equipe"
                    >
                        Inspirar pessoas a desejar o novo, mobilizar pessoas para fazer diferente.
                    </p>
                    </div>
                    <p
                        className="equipe__mais-informacoes s__tiny-text"
                        data-page="Conheça"
                        data-session="Equipe"
                        data-key="equipe"
                    >
                        PRECISA DE MAIS INFORMAÇÕES?
                    </p>
                    <button
                      className="equipe__link"
                      onClick={ () => { history.push({pathname: '/', state: { to: '.empreendimentos' }}); } }
                      onContextMenu={e => { openMenu(e, '/?to=empreendimentos') }}
                    >
                      Conheça nossos empreendimentos
                    </button>
                </div>
                <Footer link={footerLink} sections={sections} changeSection={changeSection} />
            <hr className="lineFooter" />
            </div>
        </div>
    );
}
