import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import Layout from '../../../layout/Layout/Layout.js';
import Video from '../Video';
import Informacoes from '../Informacoes';
import Galeria from '../Galeria';
import CoHaut from '../CoHaut';
import AreaComum from '../AreaComum';
import Footer from '../Footer';

import './Empreendimento.css';

export default function COHAUT002() {
  gsap.registerPlugin(ScrollToPlugin);

  useEffect(() => {
    gsap.to(window, {
      scrollTo: 0
    });
  }, []);

  const [canWheel, setCanWheel] = useState(true);
  const [indicators, setIndicators] = useState(
    [
      {
        label: 'Home',
        to: '.empreendimento',
        active: true
      },
      {
        label: 'Informações',
        to: '.informacoes',
        active: false
      },
      {
        label: 'Galeria',
        to: '.galeria',
        active: false
      },
      {
        label: 'CO-HAUT',
        to: '.e-co-haut',
        active: false
      },
      {
        label: 'A Nossa Área Comum',
        to: '.empreendimento__area1',
        active: false,
      },
      {
        label: 'A Área Comum de Todos',
        to: '.empreendimento__area2',
        active: false,
      },
      {
        label: 'Contato',
        to: '.e-footer',
        active: false,
      }
    ]
  );

  useEffect(() => {
    setCanWheel(false);
    indicators.map(indicator => {
      if (indicator.to !== ".informacoes" && indicator.active) {
        setCanWheel(true);
      }
    });
  }, [indicators]);

  const updateIndicatorActive = to => {
    setIndicators(indicators.map(indicator => {
      if (indicator.to === to) {
        return {...indicator, active: true}
      } else {
        return {...indicator, active: false}
      }
    }));
  }

  const returnIndicators = () => {
    return indicators;
  }

  const handleCanWheel = payload => {
    setCanWheel(payload);
  }

  const imagesGaleria = [

  ];

  const imagesArea1 = [

  ];

  const imagesArea2 = [

  ];

  return(
    <Layout
      sections={indicators}
      topbar={{showLogo: false, showSearch: false}}
      updateIndicatorActive={updateIndicatorActive}
      updateSection={returnIndicators}
      canWheel={canWheel}
    >
      <div className="empreendimento co-haut003">
        <Video title="CO-HAUT" number="002" src={require('../../../assets/videos/CH002.mp4')} />
        <Informacoes
          handleCanWheel={handleCanWheel}
          address={{
            city: 'Recife',
            district: 'Boa Viagem',
            street: '',
            number: ''
          }}
          types={ '' }
          units={''}
          title="CO-HAUT 003"
          subtitle="É sobre morar no espaço essencial, com o máximo de experiências."
          text={
            ''
          }
          page="CO-HAUT003"
        />
        <Galeria
          images={imagesGaleria}
          page="CO-HAUT003"
        />
        <CoHaut
          title="O nosso e o"
          subtitle="de todos"
          text={<>
            Promover a conexão é um dos propósitos da CO-HAUT. Muito além de encontros e interações, é sobre viver novas experiências e compartilhar antigas, sobre se conectar com pessoas que estão na mesma sintonia que você. <br />
            Cada CO-HAUT é um organismo totalmente único, com ambientes pensados para o NOSSO uso exclusivo, mas também espaços selecionados abertos para uso de TODOS, assim fortalecendo vínculos com a cidade que nos acolhe.
          </>}
          page="CO-HAUT003"
        />
        <div className="empreendimento__area1">
          <AreaComum
            inLeft
            images={imagesArea1}
            title={<>A nossa área <span>comum</span> .</>}
            subtitle="Morar no espaço essencial e, ainda assim, poder viver o máximo de experiências. No CO-HAUT 002, sete espaços gourmet de alto padrão possibilitam viver um momento diferente para cada dia da semana. SPA e fitness para uma rotina cheia de autocuidados. Lavanderia com sala de jogos, três ambientes de estar com TV e pet space para ajudar a desopilar e uma piscina com borda infinita para curtir a vista mais deslumbrante da cidade."
            page="CO-HAUT003"
          />
        </div>
        <div className="empreendimento__area2">
          <AreaComum
            inRight
            images={imagesArea2}
            title={<>A área comum <span>de todos</span> .</>}
            subtitle="Queremos que ainda mais gente experimente esse estilo contemporâneo de viver. Por isso, todo o pavimento térreo será aberto para a cidade, um ambiente convidativo que conta com bistrô e mini livraria com café. Logo acima, coworking, barbearia e esmalteria abertos para a cidade, proporcionando ainda mais convivência e conexão com o nosso entorno."
            page="CO-HAUT003"
          />
        </div>
        <Footer
          sections={indicators}
          changeSection={updateIndicatorActive}
          page="CO-HAUT003"
          number="003"
          coords={[-34.892095, -8.107973]}
        />
      </div>
    </Layout>
  );
}
