import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import './SearchResult.css';

export default function SearchResult({results, onMath}) {
	gsap.registerPlugin(ScrollToPlugin);

	useEffect(() => {
        if (results.length > 0) {
            gsap.to('.search-result', {
                height: '150px'
            });
        } else {
        	gsap.to('.search-result', {
        		height: 0
        	});
        }
    }, [results]);

    const navegateTo = (e, to) => {
    	e.preventDefault();
    	const fTo = `.${to.toLowerCase()}`;
		gsap.to(window, {
			scrollTo: fTo,
			duration: 2,
			onStart() {
				onMath({to: fTo});
			}
		})
    }

	return(
		<div className="search-result">
			<ul className="search-result__wrapper">
				{ results.reverse().map((result, index) => {
					return(
						<li key={index} className="search-result__item">
							<button onClick={e => { navegateTo(e, result.to ? result.to : result.session) }}>{result.session} - {result.page}</button>
						</li>
					);
				}) }
			</ul>
		</div>
	);
}
