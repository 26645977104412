import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ContextMenu from '../../../components/ContextMenu';
import CustomMenu from '../../../components/CustomMenu';

import 'swiper/swiper-bundle.min.css';
import './Empreendimentos.css';

import coHaut001 from '../../../assets/imgs/home-empreendimentos/CO-HAUT001.jpg';
import coHaut002 from '../../../assets/imgs/home-empreendimentos/CO-HAUT002.jpg';
import coHaut003 from '../../../assets/imgs/home-empreendimentos/CO-HAUT003.jpg';

import prev from '../../../assets/imgs/home-empreendimentos/arrow-left.svg';
import next from '../../../assets/imgs/home-empreendimentos/arrow-right.svg';

SwiperCore.use([Navigation]);

export default function Empreendimentos() {
  const history = useHistory();

  // Menu Contexto Personalizado
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);

  const [swipperOptions] = useState({
    loop: true,
    speed: 250,
    slidesPerView: 1.3,
    spaceBetween: 10,
    centeredSlides : true,
    simulateTouch: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      }
    }
  });

  const [empreendimentos] = useState([
    {
      id: 1,
      name: "CO-HAUT 001",
      text: "Poço da Panela - Recife",
      src: coHaut001,
      uri: '/empreendimentos/COHAUT-001',
      status: 'Em Obras',
      number: '001'
    },
    {
      id: 2,
      name: "CO-HAUT 002",
      text: "Boa Viagem - Recife",
      src: coHaut002,
      uri: '/empreendimentos/COHAUT-002',
      status: 'Lançamento',
      number: '002'
    },
    {
      id: 3,
      name: "CO-HAUT 003",
      text: "Algum lugar - Terra",
      src: coHaut003,
      uri: '/empreendimentos/COHAUT-003',
      status: 'Futuro Lançamento',
      number: '003'
    }
  ]);

  const openMenu = (e, link) => {
    setMenuItems([
      {
        id: 0,
        label: 'Abrir em uma nova guia',
        action: () => {
          const win = window.open(link, '_blank');
          if (win !== null) {
            win.focus();
          }
          setOpenContextMenu(false);
        }
      }
    ]);

    setPosX(e.nativeEvent.x);
    setPosY(e.nativeEvent.y);

    setOpenContextMenu(true);
  }

  const handleOpenMenu = payload => {
    setOpenContextMenu(payload);
  }

  const listEmpreendimentos = empreendimentos.map((empreendimento) => {
    return(
      <SwiperSlide key={empreendimento.id}>
        <div className="carrossel-3d-item-inner">
          <div className="carrossel-3d-number">{empreendimento.number}.</div>
          <img onClick={() => history.push(empreendimento.uri) } className="swiper-image" src={empreendimento.src} alt={empreendimento.name} />
          <div
            className="carrossel-3d-titles"
            onClick={() => { history.push(empreendimento.uri); }}
            onContextMenu={e => { openMenu(e, empreendimento.uri) }}
          >
            <h3 className="carrossel-3d-title">{empreendimento.name}</h3>
            <p className="carrossel-3d-subtitle">{empreendimento.status}</p>
          </div>
        </div>
      </SwiperSlide>
    );
  });

  return(
    <div className="empreendimentos s__parent s__empreendimentos" data-page="Home" data-session="Empreendimentos">
      <ContextMenu
        x={posX}
        y={posY}
        menu={<CustomMenu items={menuItems} />}
        isOpen={openContextMenu}
        handleOpenMenu={handleOpenMenu}
      />
      <div className="layout">
        <div
          className="empreendimentos__wrapper"
        >
          <Swiper
            { ...swipperOptions }
          >
            {listEmpreendimentos}

            <div className="swiper-prev">
              <img src={prev} alt="Anterior" />
            </div>
            <div className="swiper-next">
              <img src={next} alt="Próximo" />
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
