import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import ContextMenu from '../ContextMenu';
import CustomMenu from '../CustomMenu';

import './Footer.css';

import { ReactComponent as Logo } from '../../assets/logos/logo-cohaut-white.svg';
import telefone from '../../assets/icone-telefone.svg'

export default function Footer({link, sections, changeSection}) {
    gsap.registerPlugin(ScrollToPlugin);
    const history = useHistory();

    // Menu Contexto Personalizado
    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    const openMenu = (e, link) => {
      setMenuItems([
        {
          id: 0,
          label: 'Abrir em uma nova guia',
          action: () => {
            const win = window.open(link, '_blank');
            if (win !== null) {
              win.focus();
            }
            setOpenContextMenu(false);
          }
        }
      ]);

      setPosX(e.nativeEvent.x);
      setPosY(e.nativeEvent.y);

      setOpenContextMenu(true);
    }

    const handleOpenMenu = payload => {
      setOpenContextMenu(payload);
    }

    const redirect = (options) => {
        history.push(options);
    }

    const toTop = () => {
        gsap.to(window, {
            scrollTo: sections[0].to,
            onStart() {
                changeSection(sections[0].to);
            }
        })
    }

    return(
        <div className="main-footer">
            <ContextMenu
              x={posX}
              y={posY}
              menu={<CustomMenu items={menuItems} />}
              isOpen={openContextMenu}
              handleOpenMenu={handleOpenMenu}
            />
            <div className="main-footer__wrapper">
                <div className="">
                    <Logo className="main-footer__image" />
                </div>
                <div className="main-footer__content">
                    <div className="main-footer__endereço">
                        HAUT Incorporadora Design, JCPM Trade Center, Av. Antônio de Góes, 60 Salas 903 e 904, Pina - CEP 51010-000
                    </div>

                    <div className="main-footer__telefone">
                    <img src={telefone} alt="ICONE TELEFONE"></img> +55 (81) 3031-6875
                    </div>
                    <div className="main-footer__links">
                        <div className="main-footer__link">
                            <button
                                className="href__btn text-white link__btn"
                                onClick={() => { redirect({
                                    state: { to: link.to },
                                    pathname: link.uri
                                }) }}
                                onContextMenu={e => { openMenu(e, link.uri) }}
                            >
                                {link.text}
                            </button>
                        </div>
                        <div className="main-footer__topo">
                            <button
                                className="href__btn text-white topo__btn"
                                onClick={toTop}
                            >
                                Voltar para o topo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
